import React, { useEffect, useState } from 'react'
import "../Style.css"
import axios from 'axios';
import Select from "react-select";

import { apiURL } from '../../../Layout/Common/confiqurl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
const BannerNext = (props) => {
    const { setDetails } = props
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState();
    const [placeType, SetPlaceType] = useState([]);
    const [selectedPlaceOption, setSelectedPlaceOption] = useState();
    const [selectedLocationOption, setSelectedLocationOption] = useState();

    const [state, setState] = useState([])
    const [Value, setValue] = useState({
        capacity: "",
        placeType: "",
        Location: "",
    })





    useEffect(() => {


        getData()
    }, []);
    const getData = () => {
        axios.get(`${apiURL}/get_PlaceType`, {
            headers: {

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {

                storeMandapam(response.data)

                setState(response.data)

            })
    }


    const storeMandapam = (data) => {

        const newEvents = data.map((item) => ({
            label: item.data_value,
            value: item.id,
        }));

        SetPlaceType((prevState) => [...prevState, ...newEvents]);

    }

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const handleSelectPlaceChange = (selectedOption) => {
        setSelectedPlaceOption(selectedOption);
    };

    const handleSelectLocationChange = (selectedOption) => {
        setSelectedLocationOption(selectedOption);
    };
    const options = [
        { value: "option1", label: "0-250" },
        { value: "option2", label: "250-500" },
        { value: "option3", label: "500-750" },
        { value: "option3", label: "750-1000" }

    ];
    const Location = [
        { value: "option1", label: "Chennai" },
        { value: "option2", label: "Madurai" },
    ];



    const handleClick = (e) => {



        let payload = {}

        if (selectedLocationOption && selectedPlaceOption && selectedOption && selectedLocationOption.label && selectedPlaceOption.label && selectedOption.label) {

            payload = {
                Area: selectedLocationOption.label,
                Place_type: selectedPlaceOption.label,
                Floating_Capacity: selectedOption.label
            };
        } else if (selectedLocationOption && selectedOption && selectedLocationOption.label && selectedOption.label) {
            payload = {
                Area: selectedLocationOption.label,
                Floating_Capacity: selectedOption.label
            };
        }
        else if (selectedLocationOption && selectedPlaceOption && selectedLocationOption.label && selectedPlaceOption.label) {
            payload = {
                Area: selectedLocationOption.label,
                Place_type: selectedPlaceOption.label
            };
        }
        else if (selectedPlaceOption && selectedOption && selectedOption.label && selectedPlaceOption.label) {
            payload = {
                Floating_Capacity: selectedOption.label,
                Place_type: selectedPlaceOption.label
            };
        }
        else if (selectedPlaceOption && selectedPlaceOption.label) {
            payload = {
                Place_type: selectedPlaceOption.label
            };
        }
        else if (selectedOption && selectedOption.label) {
            payload = {
                Floating_Capacity: selectedOption.label
            };
        }
        else if (selectedLocationOption && selectedLocationOption.label) {
            payload = {
                Area: selectedLocationOption.label

            };
        }

        else {
            payload = {

            };
        }




        axios.post(`${apiURL}/Homepage_details`, payload, {
            headers: {
                "accept": "application/json",
                "Content-Type": " application/json",

            }
        },
        ).then((response) => { 

            setDetails(response.data)

        })
            .catch((err) => {
                {
                    toast.error(err) 
                }
            });





    }



    return (
        <div className='mx-1'>
            <div className='Banner_1_container pt-5'>
                <div className='row'>
                    <div className='col-lg-2'></div>
                    <div className=' col-lg-8 ms-5 select_container pb-3'>

                        <div>
                            <h6 className='Banner_1_Heading ps-4 pt-3'>Sort by</h6>
                        </div>
                        <div className='row mx-1 d-flex justify-content-evenly'>
                            <div className='col-lg-4 col-sm-6 '>
                                <Select
                                    value={selectedLocationOption}
                                    onChange={handleSelectLocationChange}
                                    options={Location}
                                    placeholder="Location"

                                    isSearchable={true}
                                    className="w-100 select-change"
                                />
                            </div>
                            <div className='col-lg-4 col-sm-6'>
                                <Select
                                    value={selectedPlaceOption}
                                    onChange={handleSelectPlaceChange}
                                    options={placeType}
                                    placeholder="Place Type"
                                    isSearchable={true}
                                    className="w-100 select-change"
                                />
                            </div>
                            <div className='col-lg-4 col-sm-6'>
                                <Select
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                    placeholder="Hall Capacity"

                                    options={options}
                                    isSearchable={true}
                                    className="w-100 select-change"
                                />
                            </div> 
                        </div>
                        <div className='d-flex justify-content-end mt-3'>
                            <button type='button' className="btn  me-5  text-white  " onClick={handleClick} style={{ backgroundColor: " #836D5D" }} >Apply</button>

                        </div>

                    </div>
                    <div className='col-lg-2'></div>

                </div>

                <div className=' row  pt-5 mt-5 me-5 '>
                    <div className='col-lg-1'></div>
                    <div className=' col-lg-4 mx-5 mt-3   bg-white ' style={{ borderRadius: "15px" }}>
                        <div>
                            <h2 className='Banner_1_Heading_2  pt-3 mx-4'>Search for the city and book venue</h2>
                        </div>
                        <div>
                            <p className='  pt-3 mx-4' style={{ color: "#6E5240" }}>Now you can select venue in which ever city you like</p>
                        </div>
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div className="input-group mb-4" style={{ position: "relative" }}>
                                <i className="fas fa-search " aria-hidden="true" style={{ marginLeft: "395px", zIndex: 1, color: "#949494", position: "absolute", marginTop: "10px" }}></i>
                                <input className="w-100 mb-2 mx-4 p-1" placeholder="Search..." type="text" style={{ border: "1px solid #F3F3F3", borderRadius: "6px" }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7'></div>
                </div>
            </div>
        </div>
    )
}

export default BannerNext
