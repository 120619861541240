import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../../Icons/IconIndex';
import { apiURL } from '../../../Layout/Common/confiqurl';
import axios from 'axios';
import Sidebar from './Sidebar';
import { MainContext } from '../../../Store/Context';
import Navbar from './Navbar';
import Images from '../../../Images';
import "../../LandingPage/Main/header.css";
import Banner6 from '../Main/Banner6'
import { options } from '@fullcalendar/core/preact.js';
import { toast } from 'react-toastify';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function AdvancedSearch() {
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [hallcapcity, sethallcapcity] = useState('')
  const { user } = useContext(MainContext);
  const location = useLocation();
  const { venue } = location.state || {};
  const { ID } = location.state || {};
  const { city_id } = location.state || {};
  const locations = location.state.ID;
  const venueType = venue || [];
  if (venueType) {
  } else {
  }
  const cityId = location.state && location.state.ID;
  const area = location.state && location.state.location;
  const hall = location.state && location.state.hall;
  const mlocation = location.state && location.state.mlocation;
  const budget = location.state && location.state.budget;
  const startDate = location.state && location.state.startDate;
  const endDate = location.state && location.state.endDate;
  const [values, setValues] = useState(budget ? { min: budget.min, max: budget.max } : { min: 0, max: 0 });
  const [areas, setareas] = useState([mlocation])
  const [mlist, setmlist] = useState([])
  const combinedArray = area.concat(mlist);
  const [filtered, setFiltered] = useState([]);
  const [searchresponse, setsearchresposne] = useState([])
  const labelNames = areas.map((area) => area.label);
  const [locationlist, setlocationlist] = useState([])
  const [guests, setguests] = useState(hall)
  const [facilities, setfacilities] = useState([])
  const [venuetype, setvenuetype] = useState(Array.isArray(venue) ? venue : []);
  const [venue1, setvenue1] = useState([]);
  const [areatype, setareatype] = useState([])
  const [foodtype, setfoodtype] = useState([])
  const [parking, setparking] = useState([])
  const [room, setroom] = useState([])
  const [djallow, setdjallow] = useState('')
  const [decoration, setdecoration] = useState('')
  const [mandapamlist, setmandapamlist] = useState({ data: [] });
  const [count, setCount] = useState(0);
  const [selectedPlaceOption, setSelectedPlaceOption] = useState([]);
  const [mandapamid, setmanadapamid] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [paginationLimit, setPaginationLimit] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);
  const [city, setcity] = useState('');
  const [cCity, setCCity] = useState('');
  const [arealist, setarealist] = useState([]);
  const [sessionid, setSessionId] = useState("");
  const [active, setActive] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    locationlistdetails(city_id)
    const storedCityName = sessionStorage.getItem('cityname');
    if (storedCityName) {
      setSelectedCity({ value: storedCityName, label: storedCityName });
    }
    const storedCityNamefromnewapi = sessionStorage.getItem('citynameheadernew api');
    setTimeout(() => {
      setIsLoading(false);
      fetchmandapamDetails()
    }, 1000);
    fetchmandapamDetails()
    getOptionLabel(mlocation)
    handleCheckboxChange(mlocation)
    if (budget) {
      setValues({ min: budget.min, max: budget.max });
    }
  }, [budget])
  useEffect(() => {
    const footercities = sessionStorage.getItem("footercity store");
    const sessionid = sessionStorage.getItem("selectedLocality");
    setSessionId(sessionid);
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    sessionStorage.setItem('locationName', JSON.stringify(locationlist));
    handleSearch()
  }, [searchQuery, mandapamlist.data]
  )
  useEffect(() => {
    const storedCity = sessionStorage.getItem('citynamebindin index session');
    if (storedCity !== null) {
      const cityWithoutQuotes = storedCity.replace(/^"(.*)"$/, '$1');
      setcity(cityWithoutQuotes);
    } else {
    } const storedArealist = sessionStorage.getItem('arealist');
    if (storedArealist) {
      setarealist(JSON.parse(storedArealist));
    }
    const arealist = sessionStorage.getItem('arealist');
  }, []);
  const handleSelectPlaceChange = (selectedOptions) => {
    const selectedLocalityLabels = selectedOptions.map(option => option.label);
    sessionStorage.setItem("selectedLocality", JSON.stringify(selectedLocalityLabels));
    setareas(selectedOptions);
    setmlist(selectedLocalityLabels);
    setSelectedLocality(selectedOptions);
  };
  const handleSliderChange = (value) => {
    setValues(value);
  };
  const handleFacilities = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (facilities.includes(valueToAddOrRemove)) {
      const newArray = facilities.filter(day => day !== valueToAddOrRemove);
      setfacilities(newArray);
    } else {
      const newArray = [...facilities, valueToAddOrRemove];
      setfacilities(newArray);
    }
  }
  const handleVenueType = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (venuetype.includes(valueToAddOrRemove)) {
      const newArray = venuetype.filter(item => item !== valueToAddOrRemove);
      setvenuetype(newArray);
    } else {
      const newArray = [...venuetype, valueToAddOrRemove];
      setvenuetype(newArray);
    }
  };
  const handleAreaType = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (areatype.includes(valueToAddOrRemove)) {
      const newArray = areatype.filter(day => day !== valueToAddOrRemove);
      setareatype(newArray);
    } else {
      const newArray = [...areatype, valueToAddOrRemove];
      setareatype(newArray);
    }
  }
  const handleFoodType = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (foodtype.includes(valueToAddOrRemove)) {
      const newArray = foodtype.filter(day => day !== valueToAddOrRemove);
      setfoodtype(newArray);
    } else {
      const newArray = [...foodtype, valueToAddOrRemove];
      setfoodtype(newArray);
    }
  }
  const handleparkingType = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (parking.includes(valueToAddOrRemove)) {
      const newArray = parking.filter(day => day !== valueToAddOrRemove);
      setparking(newArray);
    } else {
      const newArray = [...parking, valueToAddOrRemove];
      setparking(newArray);
    }
  }
  const handleroomType = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (room.includes(valueToAddOrRemove)) {
      const newArray = room.filter(day => day !== valueToAddOrRemove);
      setroom(newArray);
    } else {
      const newArray = [...room, valueToAddOrRemove];
      setroom(newArray);
    }
  }
  const handleDjType = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (djallow.includes(valueToAddOrRemove)) {
      const newArray = djallow.filter(day => day !== valueToAddOrRemove);
      setdjallow(newArray);
    } else {
      const newArray = [...djallow, valueToAddOrRemove];
      setdjallow(newArray);
    }
  }
  const clearvalue = (flag) => {
    if (flag == 'silder') {
      setValues({ min: 0, max: 10000 });
    } else if (flag == 'guests') {
      setguests('')
    } else if (flag == 'Facilities') {
      setfacilities([])
    } else if (flag == 'Venue') {
      setvenuetype([])
    } else if (flag == 'Area') {
      setareatype([])
    } else if (flag == 'Food') {
      setfoodtype([])
    } else if (flag == 'Parking') {
      setparking([])
    } else if (flag == 'Room') {
      setroom([])
    } else if (flag == 'Dj') {
      setdjallow('')
    } else if (flag == 'Decoration') {
      setdecoration('')
    }
  }
  const squarepagination = {
    backgroundColor: '#A9A9A9',
    color: '#fff',
    border: ' 2px solid #A9A9A9',
    borderRadius: '0',
    width: '40px',
    height: '40px',
  };
  const activePagination = {
    backgroundColor: '#6082B6',
    color: 'white',
    border: '2px solid #6082B6',
  };

  const getOptionLabel = (option) => (
    <div>
      <input
        type="checkbox"
        checked={
          sessionid === option.value ||
          areas.some((item) => item.value === option.value)
        }
        onChange={() => handleCheckboxChange(option)}
      />
      <label>{option.label}</label>
    </div>
  );
  const getOptionValue = (option) => option.value;
  const handleCheckboxChange = (option) => {
    const index = areas.findIndex((item) => item.value === option.value);
    if (index === -1) {
      setareas([...areas, option]);
    } else {
      const newSelectedOptions = [...areas];
      newSelectedOptions.splice(index, 1);
      setareas(newSelectedOptions);
    }
  };
  const getCityID = (city_id) => {
    axios.get(`${apiURL}/get_location_name?location_name=${city_id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const cityId = response.data[0].city_id;
          setCCity(cityId);
        }
      })
      .catch((error) => {
        console.error('Error fetching Chennai location data:', error);
        toast.error('Error fetching location data. Please try again later.');
      });
  };
  const locationlistdetails = async (city_id) => {
    const selectedCityId = locations;
    axios.get(`${apiURL}/location?City_id=${selectedCityId}`, {
      headers: {
        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {
        if (response.data.data != null) {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.location_name
          }));
          setlocationlist(options)
        } else {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.location_name
          }));
          setlocationlist([])
        }

      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }
  const [loading, setLoading] = useState(true);
  const [cityid, setcityid] = useState('');
  const fetchmandapamDetails = () => {
    let cityToSend;
    sessionStorage.getItem('citynameheadernewapicityid.label')
    const citynameheadernewapicityid = sessionStorage.getItem('citynameheadernewapicityid.label');
    const storedCityNameFromNewApi = sessionStorage.getItem('citynameheadernewapi');
    const citynameheadernewapi1 = sessionStorage.getItem(' citynameheadernewapicityid1.label')
    const citynameheadernewapi = sessionStorage.getItem('citynameheadernewapi');
    const citynameheadernewapicityid1 = sessionStorage.getItem('citynameheadernewapicityid1.label')
    const api = sessionStorage.getItem('citynameheadernewapi1');
    if (citynameheadernewapi && citynameheadernewapi1) {
      cityToSend = citynameheadernewapi1;
      sessionStorage.removeItem('citynameheadernewapi')
    } else if (citynameheadernewapi && citynameheadernewapi1) {
      cityToSend = citynameheadernewapi1;
    } else if (cityId) {
      cityToSend = cityId;
    } else if (citynameheadernewapicityid) {
      cityToSend = citynameheadernewapicityid;
      sessionStorage.removeItem("citynameheadernewapicityid");
    } else if (storedCityNameFromNewApi) {
      cityToSend = storedCityNameFromNewApi;
    } else {
      setLoading(false);
      return;
      handleDefaultCity()
      sessionStorage.setItem('citynameheadernewapi1', 'Chennai');
      sessionStorage.setItem('citynameheadernewapi', 'Chennai');
      const citynameheadernewapi1 = sessionStorage.setItem('Chennai')
      const citynameheadernewapi = sessionStorage.setItem('Chennai')
    }
    const venueType = venue ? (Array.isArray(venue) ? venue : [venue]) : [];
    const finalVenueType = venuetype && venuetype.length > 0 ? venuetype : venueType;
    const payload = {
      "city": cityToSend ? api : cityToSend,
      "location": combinedArray ? combinedArray : area,
      "hall_capacity": guests,
      "hall_price": `${values.min}-${values.max}`,
      "venue_type": finalVenueType,
      "parking_option": parking,
      "food_type": foodtype,
      "outside_dj": djallow,
      "outside_decoration": decoration
    }
    const delay = 2000;
    setTimeout(() => {
      axios.post(`${apiURL}/Homepage_filter_data`, payload, {
        headers: {
          "accept": "application/json",
          "Content-Type": " application/json",
        }
      },)
        .then((response) => {
          const count = response.data.count;
          setCount(response.data.count);
          setmandapamlist(response.data)
          setmanadapamid(response.data.id)
          setLoading(true)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          handleDefaultCity()
          setLoading(false)
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay);
  };
  const handleDefaultCity = () => {
    toast.warning('Unable to find the city. Default location set to Chennai');
    sessionStorage.setItem('citynameheadernewapi', 'Chennai');
    axios.get(`${apiURL}/get_location_name?location_name=Chennai`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
          sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
        } else {
          toast.error(`Error fetching Chennai location data.`);
        }
      })
      .catch((error) => {
        console.error('Error fetching Chennai location data:', error);
        toast.error('Error fetching location data. Please try again later.');
      });
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filtered.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  if (mandapamlist.data) {
    for (let i = 1; i <= Math.ceil(mandapamlist.data.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
  }
  const startIndex = Math.max(1, currentPage - Math.floor(paginationLimit / 2));
  const endIndex = Math.min(
    startIndex + paginationLimit - 1,
    Math.ceil((mandapamlist.data ? mandapamlist.data.length : 0) / itemsPerPage)
  );
  const handleSearch = () => {
    const searchLowerCase = searchQuery.toLowerCase();
    const filteredData = mandapamlist.data.filter((item) =>
      item.mandapam_name.toLowerCase().includes(searchLowerCase)
    );
    setFiltered(filteredData);
  };
  const visiblePageNumbers = pageNumbers.slice(startIndex - 1, endIndex);
  return (
    <div className={user ? "g-sidenav-show bg-gray-100 g-sidenav-hidden " : "g-sidenav-show  bg-gray-100 "}>
      <Sidebar user={user} cityID={cityId && cityId.value} cityname={cityId} areaname={area} hall={hall} budget={budget} areas={areas} setareas={setareas}
        setguests={setguests} guests={guests} values={values} setValues={setValues}
        handleSliderChange={handleSliderChange} locationlist={locationlist} setlocationlist={setlocationlist} handleSelectPlaceChange={handleSelectPlaceChange} getOptionLabel={getOptionLabel} getOptionValue={getOptionValue}
        handleCheckboxChange={handleCheckboxChange} setfacilities={setfacilities} facilities={facilities} handleFacilities={handleFacilities} setvenuetype={setvenuetype} venuetype={venuetype}
        handleVenueType={handleVenueType} setareatype={setareatype} areatype={areatype} handleAreaType={handleAreaType} setfoodtype={setfoodtype} foodtype={foodtype}
        handleFoodType={handleFoodType} setparking={setparking} parking={parking} handleparkingType={handleparkingType} setroom={setroom} room={room}
        handleroomType={handleroomType} setdjallow={setdjallow} djallow={djallow} handleDjType={handleDjType} clearvalue={clearvalue} fetchmandapamDetails={fetchmandapamDetails}
        decoration={decoration} setdecoration={setdecoration} labelNames={labelNames} venue={venue} locations={locations}
        setActive={setActive} active={active}
      />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar setActive={setActive} active={active}/>
        <div className="container-fluid py-4" style={{ minHeight: "560px" }}>
          {loading ? (
            <div className="container">
              <h4>Loading... Please Wait</h4>
            </div>
          ) : (
            mandapamlist && mandapamlist.status === "Success" && Array.isArray(mandapamlist.data) ? (
              mandapamlist.data.length !== 0 ? (
                <div>
                  <div className='container'>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className='mt-2 mx-2' style={{ fontSize: '16px', fontWeight: 'bold', color: '#3f51b5' }}>
                        {count} venues found
                      </p>
                      <div className="mx-6 dataTable-search">
                        <input
                          className="css-13cymwt-control dataTable-inputs"
                          placeholder=" Search..."
                          type="text"
                          value={searchQuery}
                          onChange={(event) => setSearchQuery(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row col-md-12 mx-1'>
                    {currentItems && currentItems.map((item, index) => (
                      <div className={`row ${user ? 'col-md-3' : 'col-md-4'}`} key={index}>
                        <div className="m-2">
                          <div className='card m-1 mt-4' style={{ height: '95%', width: '100%', marginLeft: '15px' }}>
                            <div className='mx-2'>
                              <Link to={'/FullDetails'} state={{ id: item.id,startDate:startDate,endDate:endDate }}>
                                <img
                                  src={item.image && item.image.image ? `data:image/jpeg;base64,${item.image.image}` : Images.mahall}
                                  className='advanced-img mt-2'
                                  alt={item.image && item.image.image ? "venue" : "Default Image"}
                                />
                              </Link>
                              <div className='mt-2'>
                                {item && item.mandapam_name && (
                                  <h6>
                                    {item.mandapam_name.length > 30
                                      ? `${item.mandapam_name.substring(0, 40)}...`
                                      : item.mandapam_name}
                                  </h6>
                                )}
                                {item && item.address && (
                                  <p>
                                    {item.address.length > 60 ? (
                                      <>
                                        <p>
                                          {`${item.address.substring(0, 60)}...`}
                                          <Link to={'/FullDetails'} state={{ id: item.id }}> Show More</Link>
                                        </p>
                                      </>
                                    ) : (
                                      item.address
                                    )}
                                  </p>
                                )}
                                <h4 className='hall-amount'>
                                  {item && item.startingpriceperday && (<>  <FontAwesomeIcon icon={Icons.faInr} />  <span style={{ fontSize: '20px' }}>{item.startingpriceperday}</span>  <span> Per Day</span>  </>)}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="container">
                  <p style={{ fontSize: '20px', fontWeight: '600' }}> No Record Found...</p>
                </div>
              )
            ) : (
              <div className="container">
              </div>
            )
          )}
        </div>
        <nav className="d-flex justify-content-end">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button onClick={() => paginate(currentPage - 1)} className="page-link" disabled={currentPage === 1} >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </li>
            {visiblePageNumbers.map(number => (
              <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                <button onClick={() => paginate(number)} className="page-link" style={currentPage === number ? { ...squarepagination, ...activePagination } : squarepagination} > {number} </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
              <button onClick={() => paginate(currentPage + 1)} className="page-link" disabled={currentPage === pageNumbers.length}  >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </li>
          </ul>
        </nav>
        <Banner6 city={city} arealist={arealist} />
      </main >
    </div >
  )
}

export default AdvancedSearch