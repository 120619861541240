import React, { useState, useEffect } from 'react'
import Header from '../Main/Header'
import Banner6 from '../Main/Banner6'
import axios from 'axios';
import "../Main/header.css";
import { apiURL } from '../../../Layout/Common/confiqurl';
import toast, { Toaster } from 'react-hot-toast';
import list_venue_pageimg from '../../../Images/list_venue_pageimg.png';
import venue1_new from '../../../../src/Images/venue1_new.png';
import log from '../../../../src/Images/log.jpg';
import Footer from '../Footer/index';
function Venue() {
    const [fullname, setfullname] = useState('');
    const [email, setemail] = useState('');
    const [mobilenumber, setmobilenumber] = useState('')
    const [subject, setsubject] = useState('Venue Listing')

    const [message, setmessage] = useState('')
    const [city, setcity] = useState('')

    const [arealist, setarealist] = useState([]);
    const [emailError, setEmailError] = useState('');



    const [cityname, setcityname] = useState('')        
    useEffect(() => {
 

        const storedCity = sessionStorage.getItem('citynamebindin index session');

        if (storedCity !== null) { 
            const cityWithoutQuotes = storedCity.replace(/^"(.*)"$/, '$1');
 
            setcityname(cityWithoutQuotes);
        } else {
            console.log(" "); 
        }


        const storedArealist = sessionStorage.getItem('arealist');

        if (storedArealist) {
            setarealist(JSON.parse(storedArealist));
        }


        const arealist = sessionStorage.getItem('arealist');





    }, []);



    const clearState = () => {
        setfullname('')
        setemail('')
        setmobilenumber('')
        setsubject('')
        setmessage('')
        setcity('')
    }
    
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const mobilenumberChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setmobilenumber(value);
        }
    };
    const submitForm = () => {

    

       if (!fullname) {
        toast.error('Please Enter Full Name');
        return;
    }
    if (!email) {
        toast.error('Please Enter Email ID');
        return;
    }
    if (!validateEmail(email)) {
        setEmailError('Invalid Email Address');
        toast.error('Invalid Email Address');
        return;
    }
    if (!mobilenumber || mobilenumber.length !== 10) {
        toast.error('Please Enter a valid Mobile Number');
        return;
    }
 
    if (!city) {
        toast.error('Please Enter City');
        return;
    }
    if (!message.trim()) {
        toast.error('Please Enter message');
        return;
    }
        const payload = {
            full_name: fullname,
            email_id: email,
            mobile_number: mobilenumber,
            city: city,
            subject: subject,
            message: message
        }
        if (fullname) {
            if (email) {
                if (mobilenumber) {
                    if (city) {
                        if (subject) {
                            if (message) {
                                axios.post(`${apiURL}/Venue_Freepage`, payload, {
                                    headers: {
                                        "accept": "application/json",
                                        "Content-Type": " application/json",
                                    }
                                },)
                                    .then((response) => {

                                        toast.success('Message Sent Successfully')
                                        clearState();
                                    })
                            } else {
                                toast.error('Please Enter Message')
                            }
                        } else {
                            toast.error('Please Selcet Subject')
                        }
                    } else {
                        toast.error('Please Enter City')
                    }
                } else {
                    toast.error('Please Enter Mobile Number')
                }
            } else {
                toast.error('Please Enter  Email')
            }
        } else {
            toast.error('Please Enter Full Name')
        }
    }
    return (
        <div>
            <Header logo='minilogo' city={cityname} />
            <Toaster toastOptions={{ position: "top-right" }} />
            <div className='container venue-top'>
                <div className='row mt-4'>
                    <div>
                        <h4>List Your Venue</h4>
                        <p>Welcome Venue Owner,</p>
                        <p>We are glad to add you as our partner. Please fill in the below details and we will contact you to assist on the process.</p>
                    </div>

                    <div className='col-md-6 contact-label' style={{ backgroundColor: 'white', padding: '30px' }}>

                        <div>
                            <label>Subject</label>
                            <select type='select' className='form-select mb-2' aria-readonly value={subject} onChange={(e) => setsubject(e.target.value)}>

                                <option selected>Venue Listing</option>
                                <option>Others</option>
                            </select>
                        </div>
                        <div>
                            <label>Full Name<span style={{color:'red'}}>*</span></label>
                            <input type='text' className='form-control mb-2' value={fullname} onChange={(e) => setfullname(e.target.value)} />
                        </div>
                        <div>
                            <label>Email ID<span style={{color:'red'}}>*</span></label>
                            <input type='email' className='form-control mb-2' value={email} onChange={(e) => setemail(e.target.value)} required />
                        </div>
                        <div>
                            <label>Mobile Number<span style={{color:'red'}}>*</span></label>
                            <input type='number' className='form-control mb-2 no-spinner' value={mobilenumber} onChange={mobilenumberChange} />
                        </div>
                        <div>
                            <label>City<span style={{color:'red'}}>*</span></label>
                            <input type='text' className='form-control mb-2' value={city} onChange={(e) => setcity(e.target.value)} />
                        </div>

                        <div>
                            <label>Message<span style={{color:'red'}}>*</span></label>
                            <textarea type='text' rows='7' cols='8' className='form-control mb-2' value={message} onChange={(e) => setmessage(e.target.value)}></textarea>
                        </div>
                        <div className='contact-btn mb-4'>
                            <button type='button' className='header-btn' onClick={submitForm}>Submit</button>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <img className="product-image" src={log} alt="image not found " />

                    </div>
                </div>
            </div> 
            <Banner6 city={cityname} arealist={arealist} />
            <Footer /> 
        </div>
    )
}

export default Venue