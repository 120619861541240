import React from 'react'
import { data, variable } from './data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faAirFreshener, faCamera, faCircle, faCodeFork, faCoffee, faFireExtinguisher, faLocationDot, faMoneyBill1Wave, faParking, faRestroom, faWifi, faMoneyBill, faCar, faBicycle, faCarSide, faSnowflake, faFilm, faDoorOpen, faLeaf, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { all } from 'axios';

const Details = ({ allData }) => { 
  return (
    <div className='px-3   mt-5 mx-5  mb-5'>
      <div className='row pt-5'>
        <div className='col-lg-6'>
          <h5 className='text-black mx-3 mb-3 '>Details</h5>
          <div className='card pt-4 pb-5'>

            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faUser} /> Hall Capacity</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.hallseatingcapacity ? allData.hallseatingcapacity : "Detail Not Available"}</div>

            </div>
            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faUser} /> Floating Capacity</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.hallfloatingcapacity ? allData.hallfloatingcapacity : "Detail Not Available"}</div>

            </div>
            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faAirFreshener} />Air Conditioning</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.roomsac ? allData.roomsac : "Detail Not Available"}</div>

            </div> <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faCodeFork} /> Food Type</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.foodtype ? allData.foodtype : "Detail Not Available"}</div>

            </div>



            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faRestroom} />Rooms</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.roomsnonac ? allData.roomsnonac : "Detail Not Available"}</div>

            </div>
            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faSnowflake} />Full Hall-Ac</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.fullhall_ac ? "Yes" : "No"}</div>

            </div>
            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faLocationDot} />Address</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.address ? allData.address : "Address Not Available"}</div>

            </div>

            <div className='row py-5 pb-5'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faMobileAlt} />Mobilenumber</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}> {allData && allData.mobile_number ? allData.mobile_number : "Mobile Not Available"}</div>

            </div>


          </div>
        </div>
        <div className='col-lg-6'>
          <h5 className='text-black mx-3 mb-3'>What this place offers</h5>
          <div className='card pt-4 pb-5'>

            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faDoorOpen} /> Venue Type</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.place_type}</div>

            </div>

            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faMoneyBill1Wave} /> Price</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.startingpriceperday}</div>

            </div>
            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faCar} />Car Parking</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.carparking ? "Yes" : "No"}</div>

            </div> <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faCar} /> No of Cars</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.carparking_count}</div>

            </div> <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faBicycle} />Bike Parking</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}> {allData && allData.bikeparking ? "Yes" : "No"}</div>

            </div> <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faBicycle} />No of Bikes</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.bikeparking_count}</div>

            </div>
            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faParking} />Valet Parking</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.valetparking ? "Yes" : "No"}</div>

            </div>

            <div className='row py-4'>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faFilm} />Outside Entertainment</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.outsideentertainment ? "Yes" : "No"}</div>

            </div>

            <div className='row py-4 pb-0 '>

              <div className='col-5'><h6><FontAwesomeIcon className='Details-data text-black mx-4' icon={faLeaf} />Outside Decoration</h6></div>
              <div className='col-7 text-end pe-5 Details-data' style={{ color: "#818181" }}>{allData && allData.outsidedecorationallowed ? "Yes" : "No"}</div>

            </div>
          </div>
        </div>
      </div>

    </div> 
  )
}

export default Details
