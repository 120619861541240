import React, { createContext, useState } from "react";


export const MainContext = createContext(null)


function Context({ children }) {
  const [user, setUser] = useState(false)
  return (
    <MainContext.Provider value={{ user, setUser }}>
      {children}
    </MainContext.Provider>
  );
}

export default Context