import React, { useState, useEffect } from 'react'
import Images from '../../../Images';
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { Link, useNavigate } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Select from "react-select";

function Sidebar(props) {
    const { user, cityID, values, cityname, areaname, hall, budget, handleSelectPlaceChange, handleSliderChange, guests, setguests, setfacilities, facilities, handleFacilities, setvenuetype, venuetype, handleVenueType, setareatype, locationlist,
        areatype, handleAreaType, setfoodtype, foodtype, handleFoodType, setparking, parking, handleparkingType, areas, setareas,
        setroom, room, handleroomType, setdjallow, djallow, handleDjType, clearvalue, fetchmandapamDetails, getOptionLabel, getOptionValue, decoration, setdecoration, selectedPlaceOption, area, sidebarloaction, labelNames, venue, finalVenueType, venueType, locations,
        setActive,active
    } = props; 
    const [selectedLocality, setSelectedLocality] = useState(null);
    const navigate = useNavigate()
    const [venues, setvenues] = useState([])
    const redirect = () => {
        navigate('/')
        sessionStorage.removeItem("selectedLocality");
    }

    useEffect(() => {
        const option = sessionStorage.getItem("selectedLocality");
        if (option) {
            try {
                const parsedOptions = JSON.parse(option);
                const formattedOptions = parsedOptions.map(opt => ({ label: opt, value: opt }));
                setSelectedLocality(formattedOptions);
            } catch (error) {
                console.error("Error parsing selected locality:", error);
            }
        }
        fetchVenues();
    }, []);

    const fetchVenues = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_allvenue_types`);
            if (Array.isArray(response.data.place_types)) {
                setvenues(response.data.place_types);
            } else {
                console.error('API response is not an array:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const toggleSidebar = () => {
        setActive(!active);
    };


    return (
        <aside className={`${active ? '' : 'show1'} sidenav navbar navbar-vertical navbar-expand-xs   my-3 fixed-start ms-1 `} id="sidenav-main" >
            <div className="collapse navbar-collapse mt-1  w-auto h-auto mb-5" id="sidenav-collapse-main ">
                <div className=''>
                    <img src={Images.logo} className='logo-img ml-0 mr-5' onClick={redirect} />
                </div>
                <h4>Fillter By</h4>
                <div className='locality-border mx-1'>
                    <h6 className='mt-2'>Select Locality</h6>
                    <Select placeholder="Localities" value={area} options={locationlist} onChange={handleSelectPlaceChange} getOptionLabel={getOptionLabel} getOptionValue={getOptionValue} isMulti className="w-100 select-change mb-3" />
                </div>
                <div className='locality-border mx-1'>
                    <div className='Guests'> <h6>Budget</h6> <p onClick={() => clearvalue('silder')}>Clear </p>
                    </div>
                    <InputRange maxValue={100000} className='range-silder' minValue={0} value={values} onChange={handleSliderChange} />
                </div>
                <div className='locality-border mx-1'>
                    <div className='Guests'>
                        <h6>Number of Guests</h6>
                        <p onClick={() => clearvalue('guests')}>Clear </p>
                    </div>
                    <div class="radio-container">
                        <input type="radio" id="radio1" name="guests" value='0-100' checked={guests === '0-100'} onChange={(e) => setguests(e.target.value)} />
                        <label for="radio1">Less than 100</label><br />
                        <input type="radio" id="radio2" name="guests" value='100-250' checked={guests === '100-250'} onChange={(e) => setguests(e.target.value)} />
                        <label for="radio2">100-250</label><br />
                        <input type="radio" id="radio3" name="guests" value='250-500' checked={guests === '250-500'} onChange={(e) => setguests(e.target.value)} />
                        <label for="radio3">250-500</label><br />
                        <input type="radio" id="radio4" name="guests" value='500-750' checked={guests === '500-750'} onChange={(e) => setguests(e.target.value)} />
                        <label for="radio4">500-750</label><br />
                        <input type="radio" id="radio5" name="guests" value='1000' checked={guests === '1000'} onChange={(e) => setguests(e.target.value)} />
                        <label for="radio5">1000 & above</label>
                    </div>
                </div >
                <div className='locality-border mx-1'>
                    <div className='Guests'>
                        <h6>Venue Type</h6>
                        <p onClick={() => clearvalue('Venue')}>Clear</p>
                    </div>
                    {venues.map((venue, index) => (
                        <div key={index} className="checkbox-container">
                            <input type="checkbox"
                                id={venue}
                                name={venue}
                                value={venue}
                                onChange={handleVenueType} checked={venuetype.includes(venue)} />
                            <label htmlFor={venue}>{venue}</label><br />
                        </div>
                    ))}
                </div>
                <div className='locality-border  mx-1'>
                    <div className='Guests'>
                        <h6>Food Type</h6>
                        <p onClick={() => clearvalue('Food')}>Clear </p>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" id="" name="Veg" value='Veg' onChange={handleFoodType} checked={foodtype.includes('Veg')} />
                        <label for="">Veg</label><br />
                        <input type="checkbox" id="" name="Non Veg" value='Non Veg' onChange={handleFoodType} checked={foodtype.includes('Non Veg')} />
                        <label for="">Non Veg</label><br />
                    </div>
                </div >
                <div className='locality-border  mx-1'>
                    <div className='Guests'>
                        <h6>Parking Option</h6>
                        <p onClick={() => clearvalue('Parking')}>Clear </p>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" id="" name="Bike Parking" value='Bike Parking' onChange={handleparkingType} checked={parking.includes('Bike Parking')} />
                        <label for="">Bike Parking</label><br />
                        <input type="checkbox" id="" name="Bike Parking" value='Car Parking' onChange={handleparkingType} checked={parking.includes('Car Parking')} />
                        <label for="">Car Parking</label><br />
                        <input type="checkbox" id="" name="Valet Parking" value='Valet Parking' onChange={handleparkingType} checked={parking.includes('Valet Parking')} />
                        <label for="">Valet Parking</label><br />
                    </div>
                </div>
                <div className='locality-border  mx-1 pb-2'>
                    <div className='Guests'>
                        <h6>DJ Policy</h6>
                        <p onClick={() => clearvalue('Dj')}>Clear </p>
                    </div>
                    <div class="radio-container">
                        <input type="radio" id="dj" name="dj" value='Outside DJ allowed' checked={djallow === 'Outside DJ allowed'} onChange={(e) => setdjallow(e.target.value)} />
                        <label for="dj">Outside DJ allowed</label><br />
                    </div>
                </div >
                <div className='locality-border mx-1 pb-2'>
                    <div className='Guests'>
                        <h6>Decoration policy</h6>
                        <p onClick={() => clearvalue('Decoration')}>Clear </p>
                    </div>
                    <div class="radio-container">
                        <input type="radio" id="decoration" name="decoration" value='Outside decoration allowed' checked={decoration === 'Outside decoration allowed'} onChange={(e) => setdecoration(e.target.value)} />
                        <label for="decoration">Outside decoration allowed</label><br />
                    </div>
                </div >
                <div className='locality-border   mx-1 pb-2'>
                    <div className='Guests'>
                        <h6>Alcohol Policy</h6>
                        <p onClick={() => clearvalue('Food')}>Clear </p>
                    </div>
                    <div class="checkbox-container">
                        <input type="checkbox" id="" name="Veg" value='Veg' onChange={handleFoodType} checked={areatype.includes('Veg')} disabled />
                        <label for="">Alcohol consumption allowed</label><br />
                        <input type="checkbox" id="" name="Non Veg" value='Non Veg' onChange={handleFoodType} checked={areatype.includes('Non Veg')} disabled />
                        <label for="">Outside alcohol allowed </label><br />
                    </div>
                </div >
                <div className='sidebar-apply'>
                    <button type='button' className={user ? 'Search-hide' : 'header-btn'} onClick={fetchmandapamDetails}>Search Venues</button>
                </div>
            </div>
        </aside>
    )
}
export default Sidebar