import './App.css';
import * as React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';


import LandingPage from './View/LandingPage';
import Second from './View/LandingPage/Second';
import FullDetails from './View/LandingPage/FullDetails';
import Gallery from './View/LandingPage/Gallery';
import Form from './View/LandingPage/Form';
import Contactus from './View/LandingPage/Contact/Contactus';
import Venue from './View/LandingPage/ListVenue/Venue';
import AdvancedSearch from './View/LandingPage/AdvancedSearch/AdvancedSearch';
import Errorpage from './View/LandingPage/Error Page/Errorpage';
import Errorpagenew from './View/LandingPage/Error Page/Errorpagenew';
import Footer from './View/LandingPage/index'
 
function App() {

  const token = JSON.parse(sessionStorage.getItem('data'));

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />}></Route>
          <Route path='/FullDetails' element={<FullDetails />}></Route>
          <Route path='/Gallery' element={<Gallery />}></Route>
          <Route path='/Form' element={<Form />}></Route>
          <Route path='/Contact_Us' element={<Contactus />}></Route>
          <Route path='/ListVenue' element={<Venue />}></Route>
          <Route path='/Advanced_Search' element={<AdvancedSearch />}></Route> 
          <Route path='/Errorpage' element={<Errorpage/>}></Route>
          <Route path='/Errorpagenew' element={<Errorpagenew/>}></Route>
          <Route path='/Footer' element={<Footer/>}></Route> 
        </Routes>
      </BrowserRouter> 
    </div> 
  );
} 
export default App;
