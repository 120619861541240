import React, { useState, useEffect } from 'react';
import Images from '../../../Images';
import { cardData } from '../BannnerData.js/cardData';
import { responsive } from "../BannnerData.js/data";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { toast } from 'react-hot-toast'; 
import image3 from '../../../Images/image3.jpg';
import mandapam_new from '../../../Images/mandapam_new.png';
const Banner3 = ({ venues, city, mandapamlist }) => {


  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
 
  useEffect(() => { 
    const storedEvents = sessionStorage.getItem('eventlist');

    if (storedEvents) {



      setEvents(JSON.parse(storedEvents));
    }
  }, []);




  const handlePress = (e) => {
    const value = {
      Location: e.target.value
    };
    const options = {
      Area: value.Location
    };
    axios.post(`${apiURL}/Homepage_details`, options, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json"
      }
    }).then((response) => {

      navigate("/Second", { state: { value1: response.data, value2: value } });
    }).catch((err) => {
      toast.error(err.response.data.detail);
    });
  };

 
  const [cardData, setcardData] = useState([]);

 



const [foodtype, setfoodtype] = useState([])
const [parking, setparking] = useState([])
const [room, setroom] = useState([])
const [djallow, setdjallow] = useState('')
const [decoration, setdecoration] = useState('')

const [guests, setguests] = useState('')


useEffect(()=>{
  fetchmandapamDetails()
},[]
)
  const fetchmandapamDetails = () => {
   

    const payload = {
      
        "city": "Chennai",
        "location": [],
        "hall_capacity": "",
        "hall_price": "0-100000",
        "venue_type": [],
        "parking_option": [],
        "food_type": [],
        "outside_dj": "",
        "outside_decoration": ""
    
    }     

    axios.post(`${apiURL}/Homepage_filter_data`, payload, {
    
      headers: {

        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {


        setcardData(response.data.data)
    

      })
  }


  const exploreText = city ? `Popular Venues in ${city} ` : 'Popular Venues in Chennai ';
  return (
    <div className="Banner2 mx-5 px-3">
      <h3 className='mt-0 mb-5 text-center Carousel-name'
      >
       {exploreText}
      
      </h3>

    
      
      <div className='move-card' id="marquee">
        {mandapamlist && mandapamlist.length !== 0 ? (
          mandapamlist.map((item, index) => (
            <div className='row slider-banner3' key={index}>
              <div className='col-md-4 slide-track'>
                <div className="card banner-3 slide px-4 pt-4">
                  <img className="product-image mx-2" src={mandapam_new} height={200} alt="venue" />
                  <h4 className='Carousel-name pt-3'>{item.mandapam_name || "Unknown"}</h4>
                  <div className='slides'>
                    <p className="price" style={{ fontSize: "16px" }}>{item.address}</p>
                  </div>
                  <p> 
                    <button type="button" className="btn px-5 header-btn" onClick={() => handlePress(item.name)}>Check Options</button>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p></p>
        )}
 
        {cardData && cardData.length !== 0 ? (
          cardData.map((item, index) => (
            <div className='row slider-banner3' key={index}>
              <div className='col-md-4 slide-track'>
                <div className="card banner-3 slide px-4 pt-4">
                  <img className="product-image" src={mandapam_new} height={200} alt="venue" />
                  <h4 className='Carousel-name pt-3'>{item.mandapam_name || "Unknown"}</h4>
                  <div className='slides'>
                    <p className="price" style={{ fontSize: "16px" }}>{item.address}</p>
                  </div>
                 
                </div>
              </div>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
     


  

  );
};

export default Banner3;
  