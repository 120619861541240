import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Product from "../BannnerData.js/Product";
import { productData, responsive } from "../BannnerData.js/data";
import "../Style.css";

const Banner2 = () => {
  const product = productData.map((item) => (
    <div className="text-center mx-4" style={{ height: "160px", width: "130px" }}>
      <img className="rounded-circle Carousel-image" src={item.imageurl} height={130} alt="product image" />
      <p className="text-black mx-auto Carousel-name">{item.name}</p>
    </div>
  ));

  return (
    <div className="">
       
    </div>
  );
}

export default Banner2;
 