// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Errorpage.css */
.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .home-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color:rgb(203, 12, 159);
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color:rgb(203, 12, 159);
  }
  .css{

font-size :43px;
color:#344767


  }

  .css1{


    font-size: 20px;
    color:#67748e
  }



 .my-auto {
    margin: auto;
}
.product-image {
    max-width: 100%;
    height: auto;
}

.home-button {
    background-color: #ff6690;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
}

.home-button:hover {
    background-color: #d60808;
} 
`, "",{"version":3,"sources":["webpack://./src/View/LandingPage/Error Page/Errorpage.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,kCAAkC;EACpC;EACA;;AAEF,eAAe;AACf;;;EAGE;;EAEA;;;IAGE,eAAe;IACf;EACF;;;;CAID;IACG,YAAY;AAChB;AACA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* Errorpage.css */\n.error-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    text-align: center;\n  }\n  \n  .home-button {\n    margin-top: 20px;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    border: none;\n    background-color:rgb(203, 12, 159);\n    color: white;\n    border-radius: 5px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .home-button:hover {\n    background-color:rgb(203, 12, 159);\n  }\n  .css{\n\nfont-size :43px;\ncolor:#344767\n\n\n  }\n\n  .css1{\n\n\n    font-size: 20px;\n    color:#67748e\n  }\n\n\n\n .my-auto {\n    margin: auto;\n}\n.product-image {\n    max-width: 100%;\n    height: auto;\n}\n\n.home-button {\n    background-color: #ff6690;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    font-size: 1rem;\n    border-radius: 5px;\n}\n\n.home-button:hover {\n    background-color: #d60808;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
