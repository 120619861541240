import React from "react";

export default function Product(props) {
  return (
    <div className=" text-center" style={{ height: "160px", width: "130px" }}>

      <img className="rounded-circle Carousel-image " src={props.url} width={130} height={130} alt="product image" />
      <p className="text-black mx-auto">{props.name} </p>

    </div>
  );
}
