import React, { useState, useEffect } from 'react'
import Header from '../Main/Header'
import "../Main/header.css";
import Banner6 from '../Main/Banner6'
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import toast, { Toaster } from 'react-hot-toast';
import Images from '../../../Images';
import Footer from '../Footer/index';
import { redirect } from 'react-router-dom';
import { Link, NavLink, useNavigate } from 'react-router-dom';


function Contactus() {

    const navigate = useNavigate()

    const [selectedCity, setSelectedCity] = useState('');



    const [fullname, setfullname] = useState('');
    const [email, setemail] = useState('');
    const [mobilenumber, setmobilenumber] = useState('')
    const [subject, setsubject] = useState('')
    const [message, setmessage] = useState('')
    const [cityname, setcityname] = useState('')

    const [city, setcity] = useState(''); 


    const [arealist, setarealist] = useState([]); 
    const [emailError, setEmailError] = useState('');


    useEffect(() => {


        // to get a city name by session from indexedDB.js 

        const storedCity = sessionStorage.getItem('citynamebindin index session');

        if (storedCity !== null) {
            // Remove quotes from city name if they exist
            const cityWithoutQuotes = storedCity.replace(/^"(.*)"$/, '$1');


            // Set the city state with the modified city name
            setcity(cityWithoutQuotes);
        } else {

            // Handle the case where city is not found in sessionStorage
        } const storedArealist = sessionStorage.getItem('arealist');

        if (storedArealist) {
            setarealist(JSON.parse(storedArealist));
        }

        const arealist = sessionStorage.getItem('arealist');





    }, []);



    if (city) {


    } else {

    }

    const clearState = () => {
        setfullname('')
        setemail('')
        setmobilenumber('')
        setsubject('')
        setmessage('')
    }


    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const mobilenumberChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,10}$/.test(value)) {
            setmobilenumber(value);
        }
    };

    const submitForm = () => {
        if (!subject) {
            toast.error('Please Select Subject');
            return;
        }

       if (!fullname) {
        toast.error('Please Enter Full Name');
        return;
    }
    if (!email) {
        toast.error('Please Enter Email ID');
        return;
    }
    if (!validateEmail(email)) {
        setEmailError('Invalid Email Address');
        toast.error('Invalid Email Address');
        return;
    }
    if (!mobilenumber || mobilenumber.length !== 10) {
        toast.error('Please Enter a Valid Mobile Number');
        return;
    }
 
 if (!message.trim()) {
        toast.error('Please Enter Message');
        return;
    }

        const payload = {
            full_name: fullname,
            email_id: email,
            mobile_number: mobilenumber,
            subject: subject,
            message: message
        }
        if (fullname) {
            if (email) {
                if (mobilenumber) {
                    if (subject) {
                        if (message) {
                            axios.post(`${apiURL}/Contact_us`, payload, {
                                headers: {
                                    "accept": "application/json",
                                    "Content-Type": " application/json",
                                }
                            },)
                                .then((response) => { 
                                    toast.success('Message Sent Successfully')
                                    clearState();
                                })
                        } else {
                            toast.error('Please Enter Message')
                        }
                    } else {
                        toast.error('Please Select Subject')
                    }
                } else {
                    toast.error('Please Enter Mobile Number')
                }
            } else {
                toast.error('Please Enter  Email')
            }
        } else {
            toast.error('Please Enter Full Name')
        }
    }

    const redirect = () => {
        navigate('/')
      }
    
    const exploreText = cityname ? `Explore Venues in ${cityname} by Locality` : 'Explore Venues by Types';
    return (
        <div>
            <Header logo='minilogo' city={city} arealist={arealist} />
            <Toaster toastOptions={{ position: "top-right" }} />
            <div className='container venue-top'>
                <div className='row mt-3'>
                    <div className='col-md-6 contact-label' style={{ backgroundColor: 'white', padding: '30px' }}>
                        <h4 className='contact-us-head'>Contact Us</h4>

                        <div>
                            <label>Subject<span style={{color:'red'}}>*</span></label>
                            <select type='select' className='form-select mb-2' value={subject} onChange={(e) => setsubject(e.target.value)}>
                                <option value=''>Select</option>
                                <option>Venue Listing</option>
                                <option>Others</option>
                            </select>
                        </div>
                        <div>
                            <label>Full Name<span style={{color:'red'}}>*</span></label>
                            <input type='text' className='form-control mb-2' value={fullname} onChange={(e) => setfullname(e.target.value)} />
                        </div>
                        <div>
                            <label>Email ID<span style={{color:'red'}}>*</span></label>
                            <input type='text' className='form-control mb-2' value={email} onChange={(e) => setemail(e.target.value)} required />
                        </div>
                        <div>
                            <label>Mobile Number<span style={{color:'red'}}>*</span></label>
                            <input type='text' className='form-control mb-2' value={mobilenumber} onChange={mobilenumberChange} />
                        </div>

                        <div>
                            <label>Message<span style={{color:'red'}}>*</span></label>
                            <textarea type='text' rows='8' cols='10' className='form-control mb-2' value={message} onChange={(e) => setmessage(e.target.value)} ></textarea>
                        </div>
                        <div className='contact-btn'>
                            <button type='button' className='header-btn' onClick={submitForm}>Submit</button>
                        </div>
                    </div>
                    <div className='col-md-6'  style={{ paddingLeft: '80px' }}>
                        <h4 className='contact-us-head mt-4'>About Us</h4>
                        <div>
                            <p style={{ fontWeight: '400' }}>Welcome to<span style={{ fontWeight: 'bold', color: '#f39c12' }}> <a onClick={redirect}>BookMeVenue,</a></span></p>

                            <p className='about-us-para'>We appreciate you for choosing us as your trusting partner to find your perfect venue.</p>

                            <p className='about-us-para'>We believe that choosing the venue plays the vital role in all the events from small party to big wedding ceremony and thats why we started Event-portal to make it easier for you. We guarantee here not only easy but affordable and perfect venue selection for you.</p>

                            <p className='contact-sub-head'>Our Vision:</p>
                            <p className='about-us-para'>Empowering seamless event experiences through our innovative hall booking platform, where every occasion finds its perfect space. We envision a future where planning events is effortless, efficient, and enjoyable for everyone involved, creating cherished memories in every gathering</p>

                            <p className='contact-sub-head'>How do we help:</p>
                            <p className='about-us-para'>We offer user-friendly and trust-worthy platform for finding the best venue for all your celebrations.</p>
                            <p className='about-us-para'>We have a dedicated customer support to assist you on the whole process wherever needed.And we offer these on zero cost too. Yes, all our services are absolutely free for all our customers and partners.</p>
                            <p>Have a Delightful Celebration!</p>

                        </div>
                    </div>
                    
                    
                </div>
                
            </div>
            
            <Banner6 city={city} arealist={arealist} />
            <Footer /> 
           
        </div>
         
    )
    
} 

export default Contactus