import Images from "../../../Images";


export const secondData = [
  {
    id: 1,
    Heading: "Pai Vista",
    imageurl: Images.cardimage13,
    name: "Chennai",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 2,
    Heading: "La Marvella",

    imageurl: Images.cardimage14,
    name: "Mumbai",
    about: "You can experience luxury and best Ambeince with us"
  },
  {
    id: 3,
    Heading: "Sindhoor Convention Centre",
    imageurl: Images.cardimage15,
    name: "Bengaluru",
    about: "You can experience luxury and best Ambeince with us"


  },
  {
    id: 4,
    Heading: "The WoodRose",

    imageurl: Images.cardimage16,
    name: "Mysore",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 5,
    Heading: "Samskruthi Swasthik",

    imageurl: Images.cardimage17,
    name: "Visakapatnam",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 6,
    Heading: "Sri Sai Convention Hall",

    imageurl: Images.cardimage18,
    name: "Thiruvananthapuram",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 7,
    Heading: "MLR Convention Centre",

    imageurl: Images.cardimage19,
    name: "Nagpur",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 8,
    Heading: "The Paul Bangalore",

    imageurl: Images.cardimage20,
    name: "Jaipur",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 9,
    Heading: "Shiva Shakti Convention Hall",

    imageurl: Images.cardimage21,
    name: "Dehli",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 10,
    Heading: "Radha Convention Hall",

    imageurl: Images.cardimage22,
    name: "Goa",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 11,
    Heading: "Sri Convention",

    imageurl: Images.cardimage23,
    name: "Coimbatore",
    about: "You can experience luxury and best Ambeince with us"

  },
  {
    id: 12,
    Heading: "V Legacy",

    imageurl: Images.cardimage24,
    name: "Indore",
    about: "You can experience luxury and best Ambeince with us"

  },
];