import Images from "../../../Images";


export const cardVenue = [
  {
    id: 1,
    imageurl: Images.Wedding_Resorts,

      quote: "Celebrate life’s special moments in our elegant Weding Resorts."

  },
  {
    id: 2,
    imageurl: Images.Banquet_Halls,
   
   quote: "Create lasting memories in a venue that dazzles and delights."

  },
 
  {
    id: 6,
    imageurl: Images.beach_house,
  
       quote: "A beautiful venue sets the tone for an unforgettable Wedding ."

  },
  {
    id: 7,
    imageurl: Images.Marriage_Garden,
      quote: "A beautiful venue sets the tone for an unforgettable Wedding ."
 

  },
  {
    id: 8,
    imageurl: Images.Wedding_Lawns,
   
       quote: "Breathtaking views and open skies – the perfect Wedding Lawn."

  },
  {
    id: 9,
    imageurl: Images.Marriage_Halls,

        quote: "Host your wedding in our spacious and beautiful Marriage Halls."

  },
  {
    id: 10,
    imageurl: Images.Function_Halls,
    
        quote: "Host your wedding in our spacious and beautiful Function Halls."
  

    
  },

  {
    id: 11,
    imageurl: Images.Party_Halls,
    
      quote: "Host your next event in style! Our party halls offer the perfect blend of luxury and comfort."
  

    
  },
 
  {
    id: 12,
    imageurl: Images.Farmhouses,
  
        quote: "Host your wedding in our spacious and beautiful Farm Houses."

    
  },
 
  {
    id: 13,
    imageurl: Images.mini_hall,
  

        quote: "Host your wedding in our spacious and beautiful Wedding Halls."
    
  },
 
  {
    id: 14,
    imageurl: Images.Kalyana_Mandapams,
    quote: "Host your wedding in our spacious and beautiful marriage.",
  

    
  },
 
  {
    id: 15,
    imageurl: Images.Wedding_Hotels,
   
  

        quote: "Host your wedding in our spacious and beautiful Marriage Halls."
    
  },
 
  {
    id: 16,
    imageurl: Images.Convention_Halls,
  
        quote: "Host your wedding in our spacious and beautiful Convention Halls"

    
  },
 
  {
    id: 17,
    imageurl: Images.Marriage_Garden,
  

        quote: "Host your wedding in our spacious and beautiful Marriage Gardens."
    
  },
 
 
  
];