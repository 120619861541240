import React from 'react';
import '../../../../src/App.css';

const Footer = () => {
  return (
    <footer className="footer"> 
          <div className="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
            Developed by <a href="https://datamoo.ai" className="text-primary">DATAMOO.AI</a>  | Copyright © 2024 <a href="javascript:void(0);" className="text-primary">BookMeVenue.com</a>. All rights reserved.
          </div> 
    </footer>
  );
}

export default Footer;
