import React, { useState, useEffect } from 'react';
import Images from '../../../Images';
import { cardVenue } from '../BannnerData.js/cardVenue';
import { responsive } from "../BannnerData.js/data";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
import { toast } from 'react-hot-toast'; 
import image3 from '../../../Images/image3.jpg';
import mandapam_new from '../../../Images/mandapam_new.png';
const BannerVenue = () => {


  const navigate = useNavigate();

  const [venues, setvenues] = useState([]); 
 

  const [mergedVenues, setMergedVenues] = useState([]);
 





  useEffect(() => {
    fetchVenues ()
   
  }, []);




 
const fetchVenues = async () => {
  try { 
    const response = await axios.get(`${apiURL}/get_allvenue_types`); 
    if (Array.isArray(response.data.place_types)) {
      setvenues(response.data.place_types);
      mergeData(response.data.place_types);
    } else {
      console.error('API response is not an array:', response.data);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const mergeData = (apiData) => {
  const merged = apiData.map((venue, index) => ({
    ...cardVenue[index % cardVenue.length],  
    name: venue,
  }));
 
  setMergedVenues(merged);
};


  return (
    <div className="Banner2 mx-5 px-3">
      <h3 className='mt-4 mb-5 text-center Carousel-name'
      > 
         <p className='venu_css'>Popular Venue Types</p>
      
      </h3>
            <div className='move-card' id="marquee">
        
          <>
          {mergedVenues.map((item, index) => (
              <div className='row slider-banner3' key={index}>
                <div className='col-md-4 slide-track'>
                  <div className="card  banner-3 slide  ">
                    <img className="product-image px-4 pt-4" src={item.imageurl} height={200} alt="product" />
                 
                    <h4  className='Carousel-name pt-3  px-3'>{item.name}</h4>
             
                    <div>
                    <p className='px-3'> {item.quote}</p>
                     </div> 
                  </div>
                </div>
              </div>
            ))
            }
          </>
    
      </div>

    </div>
  );
};

export default BannerVenue;
