import React from 'react'
import { secondData } from '../BannnerData.js/secondCard'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Part3 = () => {
  return (
    <div className="Banner2 mx-5 px-3" >
      <h3 className='my-4 text-center  Carousel-name '>Explore Venues in all the major Indian Cities</h3>
      <div className=' row'>
        {
          secondData.map((item) => {
            return (
              <div className='col-lg-4 col-sm-6  my-2'>
                <div className="card px-4 pt-4">
                  <img className="product--image ms-3" src={item.imageurl} height={200} alt="product image" />
                  <h5 className='Carousel-name pt-3'>{item.Heading}</h5>
                  <h6 className='Carousel-name pt-1'><FontAwesomeIcon className='  mx-1' icon={faLocationDot} />{item.name}</h6>

                  <p className="price" style={{ fontSize: "18px", minHeight: "350px" }}>{item.about}</p>
                  <p>
                    <div className='col-12'>
                      <button type="button" class="btn px-5 mt-4 text-white w-100" style={{ backgroundColor: " #836D5D" }} >check Options</button>

                    </div>
                  </p>
                </div>
              </div>
            )
          })
        }

      </div>

    </div>
  )
}

export default Part3