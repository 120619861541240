import React, { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import "../Style.css"
import axios from 'axios';
import Select from "react-select";

import { apiURL } from '../../../Layout/Common/confiqurl';
import { Link, useNavigate } from 'react-router-dom';

import Images from '../../../Images';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Banner3 from './Banner3';
import { set } from 'react-hook-form';

import Swal from 'sweetalert2';
import { setRef } from '@material-ui/core';






const Banner = ({ cityidnew }) => {

    const [newcityid, setnewcityid] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const today = new Date().toISOString().substr(0, 10);
    const [cityLabel, setCityLabel] = useState('');
    const storedCity = sessionStorage.getItem('citynamebindin index session');
    const getlocationwithid = () => {
        axios.get(`${apiURL}/get_location_name?location_name=${storedCity}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setcityid(response.data[0].city_id);
                    setcityId(response.data[0].location_name)
                    setcitynameheader(response.data[0].location_name);
                    // sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id); 
                } else {
                    toast.error('No locations found, default city selected.');
                }
            })

            .catch((error) => {
                console.error('Error fetching location data:', error);
                if (error.response && error.response.data && error.response.data.detail === "No locations found") {
                    // handleDefaultCity();
                } else {
                    // toast.error('Error fetching location data. Please try again later.');
                }
            });

    };

    // const handleDefaultCity = () => {
    //     const defaultCity = { city_id: '1', location_name: 'chennai' };
    //     // setTimeout(() => {
    //     //     toast.error('Unable to find the city, Default location set to Chennai');
    //     // }, 3000); 
    //     setcityid(defaultCity.city_id);
    //     setcitynameheader(defaultCity.location_name); 

    //     axios.get(`${apiURL}/get_location_name?location_name=Chennai`, {
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //         .then((response) => {
    //             if (response.data && response.data.length > 0) {
    //                 // setcityid(1);
    //                 // setcity('chennai'); 
    //                 setcityid('chennai');
    //                 setcityId('chennai')

    //       sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
    //       sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
    //       sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
    //       sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);


    //             } else {
    //                 toast.error(`Error fetching Chennai location data.`);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching Chennai location data:', error);
    //             toast.error('Error fetching location data. Please try again later.');
    //         });
    // };


    const navigate = useNavigate()



    const [placeType, SetPlaceType] = useState([]);
    const [sliderValue, setSliderValue] = useState(null);
    const [slider, setslider] = useState(false)
    const [values, setValues] = useState({ min: 0, max: 100000 });




    const [selectedPlaceOption, setSelectedPlaceOption] = useState([]);

    const [selectedlocationcount, setselectedlocationcount] = useState([]);



    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [state, setState] = useState([])
    const [Value, setValue] = useState({
        capacity: "",
        placeType: "",
        Location: "",
    })


    const [citylist, setcitylist] = useState([])

    const [cityid, setcityid] = useState('');

    const [cityId, setcityId] = useState();
    const [hallcapcity, sethallcapcity] = useState('');

    const [hallcount, sethallcount] = useState('')

    const [advancedcityid, setadvancedcityid] = useState();

    const [locationname, setlocationname] = useState([])
    const [locationlist, setlocationlist] = useState([])

    const [locationid, setlocationid] = useState([])


    const [citynameheader, setcitynameheader] = useState('')
    const images = [
        Images.newimg,
        Images.img2
    ];
    const handleSliderChange = (value) => {
        setValues(value);
    };

    function calculateColor(value) {
        if (value <= 2500) {
            return 'bisque'; // Example color for the first range
        } else if (value <= 5000) {
            return 'lightblue'; // Example color for the second range
        } else {
            return 'lightgreen'; // Example color for the third range
        }
    }

    const handleSelectChange = (hallcapcity) => {
        sethallcapcity(hallcapcity);
        sethallcount(hallcapcity.label)
    };


    const handleSelectPlaceChange = (hallcapcity) => {

        setSelectedPlaceOption(hallcapcity);
        setselectedlocationcount(hallcapcity);

        setlocationname(hallcapcity);
        // selectedPlaceOption(hallcapcity && hallcapcity.map(option => option.label));
        setlocationname(hallcapcity && hallcapcity.map(option => option.label));

        setIsMenuOpen(true)

    };
    const handleSelectLocationChange = (hallcapcity) => {

        const selectedCityId = hallcapcity.value;
        setcityid(selectedCityId.toString());
        const newCityId = hallcapcity.value;
        const newCityId1 = hallcapcity.label;
        setcityId(hallcapcity.value)
        setadvancedcityid(hallcapcity.value)
        locationlistdetails(hallcapcity.value)
        sessionStorage.setItem('citynameheadernewapi1', hallcapcity.label);
        sessionStorage.setItem('citynameheadernewapicityid1', hallcapcity.value);
    };

    const getOptionLabel = (option) => (

        <div>

            <input
                type="checkbox"
                // checked={locationname.some((item) => item.value === option.value)}
                checked={selectedPlaceOption.some((item) => item.value === option.value)}
                onChange={() => handleCheckboxChange(option)}
            />
            <label>{option.label}</label>
        </div>
    );

    const getOptionValue = (option) => option.value;
    const getData = () => {
        axios.get(`${apiURL}/get_PlaceType`, {
            headers: {

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {

                const options = response.data.map(item => ({
                    value: item.id,
                    label: item.data_value
                }));
                SetPlaceType(options)
                setState(response.data)

            })
    }

    const getCitys = () => {
        axios.get(`${apiURL}/cities`, {
            headers: {

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {

                const options = response.data.data.map(item => ({
                    value: item.id,
                    label: item.city_name
                }));
                setcitylist(options)
            })
    }

    const locationlistdetails = (id) => {
        axios.get(`${apiURL}/location?City_id=${id}`, {
            headers: {

                "accept": "application/json",
                "Content-Type": " application/json",
            }
        },)
            .then((response) => {

                const options = response.data.data.map(item => ({
                    value: item.id,
                    label: item.location_name
                }));
                setlocationlist(options)
            })
            .catch((error) => {
                console.error("An error occurred:", error);
            });
    }



    const options = [
        { value: "option1", label: "0-250" },
        { value: "option2", label: "250-500" },
        { value: "option3", label: "500-750" },
        { value: "option3", label: "750-1000" }

    ];
    const Location = [
        { value: "option1", label: "Chennai" },
        { value: "option2", label: "Madurai" },
    ];





    const handleCheckboxChange = (option) => {
        const index = selectedPlaceOption.findIndex((item) => item.value === option.value);
        if (index === -1) {
            setSelectedPlaceOption([...selectedPlaceOption, option]);
        } else {
            const newhallcapcitys = [...selectedPlaceOption];
            newhallcapcitys.splice(index, 1);
            setSelectedPlaceOption(newhallcapcitys);
        }
    }

    const handleNullCityId = () => {


        const showAlert = () => {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                // text: 'Please choose a city',
                confirmButtonColor: '#3085d6',

                confirmButtonText: 'OK',
            });
        };



    };




    const filterOption = (option, searchText) => {
        return (
            option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.value.toLowerCase().includes(searchText.toLowerCase())
        );
    };
    const locationOptions = locationlist.map(item => ({
        label: item.location_name,
        value: item.id
    }));

    const myFunction = () => {
        const storedCityId1 = sessionStorage.getItem('citynameheadernewapicityid1');
        const storedCitylabel = sessionStorage.getItem('citynameheadernewapicitylablel1');


        if (storedCityId1 !== null && storedCityId1 !== undefined) {
            setcityid(storedCityId1.toString());
        } else {
            console.log("");

        }
    }



    useEffect(() => {
        const intervalId = setInterval(() => {
            myFunction();
        }, 1000);

        // handleDefaultCity();
        // return () => clearInterval(intervalId);
    }, []);



    useEffect(() => {
        getData()
        getCitys()
        // getlocationwithid()
    }, []);
    const handleSearchVenues = () => {
        sessionStorage.setItem('checkin', startDate);
        sessionStorage.setItem('checkout', endDate);
    };


    // const selectedCityId = cityidnew || cityi
    return (
        // <ToastProvider>

        <div className='mx-1'>
            <Toaster toastOptions={{ position: "top-right" }} />
            <div className='Banner_1_container pt-5'>

                <div className='row mt-8'>


                    <div className='col-lg-3'></div>


                    <div className=' col-lg-6 select_containers pb-3'>


                        India’s No.1 trust-worthy platform for finding the best venue


                    </div>


                </div>


                <div className='row mt-5'>


                    <div className='col-lg-1'></div>


                    <div className=' col-lg-10 select_container pb-3'>


                        <div className='row mx-1 d-flex justify-content-evenly mt-3'>

                            <div className='col-lg-3 col-sm-6 mb-2'>
                                <Select

                                    value={citylist.find(city => city.value === parseInt(cityid, 10))}
                                    onChange={handleSelectLocationChange}
                                    options={citylist}
                                    placeholder="City"
                                    isSearchable={true}
                                    className="w-100 select-change"
                                />
                            </div>

                            <div className='col-lg-3 col-sm-6 mb-2'>
                                {selectedPlaceOption ? (
                                    <Select
                                        placeholder="Localities"
                                        value={selectedPlaceOption}

                                        options={locationlist}
                                        onChange={handleSelectPlaceChange}
                                        getOptionLabel={getOptionLabel}
                                        getOptionValue={getOptionValue}
                                        onMenuOpen={() => setIsMenuOpen(true)}
                                        onMenuClose={() => setIsMenuOpen(false)}
                                        menuIsOpen={isMenuOpen}
                                        isSearchable
                                        isMulti
                                        // filterOption={customFilterOption}
                                        className="w-100 select-change"
                                    />
                                ) : (
                                    <p>No location matched</p>
                                )}

                            </div>



                            <div className='col-lg-3 col-sm-6 mb-2'>
                                <Select

                                    value={hallcapcity}
                                    onChange={handleSelectChange}
                                    placeholder={"Hall Capacity"}
                                    options={options}
                                    isSearchable={true}
                                    className="w-100 select-change"
                                />
                            </div>
                            <div className='col-lg-3 col-sm-6 mb-2'>
                                <input type='text'

                                    value={` ${values.min} -  ${values.max}`}

                                    onClick={() => setslider(!slider)} className='form-control' placeholder='Budget' />
                                {slider &&
                                    <div className='budget-banner px-2'>

                                        <InputRange
                                            maxValue={100000}
                                            className='range-silder'
                                            minValue={0}
                                            value={values}
                                            onChange={handleSliderChange}
                                        />

                                    </div>
                                }
                            </div>
                            <div className='col-lg-3 col-sm-6 mb-2'>
                                <label>Checkin</label>
                                <input type="date" class="form-control" min={today} value={startDate} onChange={(e) => setStartDate(e.target.value)} onFocus={(e) => e.target.showPicker()} />
                            </div>
                            <div className='col-lg-3 col-sm-6 mb-2'>
                                <label>Checkout</label>
                                <input type="date" class="form-control" min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} onFocus={(e) => e.target.showPicker()} />
                            </div>
                        </div>

                        <div>
                            <div className='d-flex justify-content-end mt-3'>
                                <Link to={'/Advanced_Search'} state={{ city_id: advancedcityid, ID: cityid, advancedcityid, location: locationname, hall: hallcount, budget: values, mlocation: selectedPlaceOption, startDate: startDate, endDate: endDate, }}>
                                    <button type='button' className="btn me-3 header-btn text-white" onClick={handleSearchVenues} >Search Venues</button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default Banner