import { Avatar } from '@material-ui/core'
import React from 'react'
import Images from '../../../Images'
import { Review } from './data'
import { Container, Row, Col } from 'react-bootstrap';

const data = [
  { id: 1, title: 'Card 1' },
  { id: 2, title: 'Card 2' },
  { id: 3, title: 'Card 3' },
  { id: 4, title: 'Card 4' },
  { id: 5, title: 'Card 5' },
  { id: 6, title: 'Card 6' },
  { id: 7, title: 'Card 7' },
  { id: 8, title: 'Card 8' },
];
const Reviews = () => {
  return (

    <div className='px-3   mt-5 mx-5  mb-5'>
     

    </div>
  )
}

export default Reviews
