import React from 'react'
import Header from '../Main/Header'
import Banner from '../Main/Banner'
import Part1 from './Part1'
import Part3 from './Part3'
import Banner6 from '../Main/Banner6'
import { useLocation } from 'react-router-dom'
import BannerNext from '../Main/BannerNext'

const Second = () => {
  const location = useLocation()
  const allData = location.state.value1
  const singleData = location.state.value2


  return (
    <div>

      <Part1 allData={allData} /> 
      <Banner6 />

    </div>
  )
}

export default Second
