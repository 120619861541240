import React from 'react'
import Header from '../Main/Header'
import Enquiry from './Enquiry'
import Card from './Card'

const Form = () => {
  return (
    <div> 
      <Enquiry />
      <Card />
    </div>
  )
}

export default Form
