import image1 from './1.jpg';
import image2 from './2.jpg';
// import image3 from './3.jpg';
import image3 from './image3.jpg';
import mini from './mini.jpg';
import star1_resize from './star.png';
import mahall from './mahall.png';
import party from './party.jpg';
import wedding from './wedding.jpg';
import carousel2 from "./LandingImage/Rectangle 58.png"
import carousel1 from "./LandingImage/Rectangle57.png"
import carousel3 from "./LandingImage/Rectangle 59.png"
import carousel4 from "./LandingImage/Rectangle 60.png"
import carousel5 from "./LandingImage/Rectangle 61.png"
import carousel6 from "./LandingImage/Rectangle 62.png"
import cardimage1 from "./LandingImage/Rectangle 67.png"
import cardimage2 from "./LandingImage/Rectangle 68.png"
import cardimage3 from "./LandingImage/Rectangle 69.png"
import cardimage4 from "./LandingImage/Rectangle 73.png"
import cardimage5 from "./LandingImage/Rectangle 75.png"
import cardimage6 from "./LandingImage/Rectangle 77.png"
import cardimage7 from "./LandingImage/Rectangle 89.png"
import cardimage8 from "./LandingImage/Rectangle 90.png"
import cardimage9 from "./LandingImage/Rectangle 91.png"
import cardimage10 from "./LandingImage/Rectangle 93.png"
import cardimage11 from "./LandingImage/Rectangle 94.png"
import cardimage12 from "./LandingImage/Rectangle 95.png"
import profile from "./LandingImage/Image Placeholder.png"
import line16 from "./LandingImage/Line 16.png"
import line15 from "./LandingImage/Line 15.png"
import line17 from "./LandingImage/Line 17.png"
import line18 from "./LandingImage/Line 18.png"
import cardimage13 from "./LandingImage/Second/Rectangle 77.png"
import cardimage14 from "./LandingImage/Second/Rectangle 109.png"
import cardimage24 from "./LandingImage/Second/Rectangle 110.png"
import cardimage15 from "./LandingImage/Second/Rectangle 111.png"
import cardimage16 from "./LandingImage/Second/Rectangle 112.png"
import cardimage17 from "./LandingImage/Second/Rectangle 113.png"
import cardimage18 from "./LandingImage/Second/Rectangle 114.png"
import cardimage19 from "./LandingImage/Second/Rectangle 115.png"
import cardimage20 from "./LandingImage/Second/Rectangle 116.png"
import cardimage21 from "./LandingImage/Second/Rectangle 117.png"
import cardimage22 from "./LandingImage/Second/Rectangle 118.png"
import cardimage23 from "./LandingImage/Second/Rectangle 119.png"
import galleryimage1 from "./LandingImage/Second/Rectangle 12887.png"
import galleryimage5 from "./LandingImage/Second/Rectangle 12888.png"
import galleryimage2 from "./LandingImage/Second/Rectangle 12890.png"
import galleryimage3 from "./LandingImage/Second/Rectangle 12891.png"
import galleryimage4 from "./LandingImage/Second/Rectangle 12892.png"
import galleryimage11 from "./LandingImage/Second/Rectangle 12886.png"
import galleryimage6 from "./LandingImage/Second/Rectangle 12885.png"
import galleryimage7 from "./LandingImage/Second/Rectangle 12884.png"
import galleryimage8 from "./LandingImage/Second/Rectangle 12895.png"
import galleryimage9 from "./LandingImage/Second/Rectangle 12896.png"
import galleryimage10 from "./LandingImage/Second/Rectangle 12894.png"
import galleryimage12 from "./LandingImage/Second/Rectangle 12897.png"
import galleryimage13 from "./LandingImage/Second/Rectangle 12893.png"
import galleryimage14 from "./LandingImage/Second/Rectangle 12899.png"

import Vector from "./LandingImage/Second/Vector.png"
import profile3 from "./LandingImage/Second/Ellipse 2.png"
import profile1 from "./LandingImage/Second/Ellipse 26.png"
import profile2 from "./LandingImage/Second/Ellipse3.png"
import madabam02 from "./LandingImage/Second/madabam02.png"
import imagek from "./LandingImage/Second/image.png"
import star from './howis.png';
import newimg from './new1.jpg';
import img2 from './LandingImage/Rectangle.png';
import imagewed1 from './image2.jpg';
import imagewed2 from './image3.jpg';
import imagewed3 from './image4.jpg';
import hall1 from './hall1.jpg';
import banquet from './banquet_hall.jpg';
import rangeslider from './range.png';
import newlogo from './newlogo.png';
import search from './search.png';
import book from './book.png';
import check from './check.png';
import free from './free.png'
import star1 from './star1_resize.png'
import mandapam_new from './mandapam_new.png';
import list_venue_pageimg from './list_venue_pageimg.png';
import venue1_new from './venue1_new.png';
import logo_event from './logo_event.png'
import logo from './logo.png'
import mandap_yel from './mandap_yel.jpg';
import foot_1 from './foot_1.png';
import foot_2 from './foot_2.png';
import foot_3 from './foot_3.png';
import foot_4 from './foot_4.png';
import foot_5 from './foot_5.png';
import foot_6 from './foot_6.png';
import image_6 from './image_6.png';
import image_8 from './image_8.png';
import image_9 from './image_9.png';
import image_11 from './image_11.png';
import image_12 from './image_12.png';
import error_404 from './error_404.png';
import Wedding_Resorts from './Wedding_Resorts.jpg';
import Function_Halls from './Function_Halls.jpg';
import Resort from './Resort.jpg';

import Party_Halls from './Party_Halls.jpg';

import Farmhouses from './Farmhouses.jpg';

import Convention_Halls from './Convention_Halls.jpg';
import beach_house from './beach_house.jpg';
import Banquet_Halls from './Banquet_Halls.jpg';
import Marriage_Halls from './Marriage_Halls.jpg';

import Marriage_Garden from './Marriage_Garden.jpg';
import Kalyana_Mandapams from './Kalyana_Mandapams.jpg';

import Wedding_Lawns from './Wedding_Lawns.jpg';
import Wedding_Hotels from './Wedding_Hotels.jpg';

import image_10 from './image_10.png';
import arr from './arr.png';
import venu_carousels from './venu_carousels.jpg';
import venu_caro1 from './venu_caro1.jpg';
import venu_caro2 from './venu_caro2.jpg';
import mini_hall from './mini_hall.jpg';
import venu_caro3 from './venu_caro3.jpg';
import venu_caro4 from './venu_caro4.jpg';
import venu_caro5 from './venu_caro5.jpg';
import venu_caro6 from './venu_caro6.jpg';
import venu_caro7 from './venu_caro7.jpg';
import venu_caro8 from './venu_caro8.jpg';
import venu_caro9 from './venu_caro9.jpg';
import venu_caro10 from './venu_caro9.jpg';
import venu_caro11 from './venu_caro9.jpg';
import venu_caro12 from './venu_caro9.jpg';
import logo4 from './log4.jpg';
import fav_icon from './fav-icon.png';

const Images = {
    search,
    star1,
    book,
    check,
    free,
    image1,
    imagek,
    image2,
    image3,
    mini,
    party,
    wedding, carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    carousel6,
    cardimage1,
    cardimage2,
    cardimage3,
    cardimage4,
    cardimage5,
    cardimage6,
    cardimage7,
    cardimage8,
    cardimage9,
    cardimage10,
    cardimage11,
    cardimage12,
    cardimage13,
    cardimage14,
    cardimage15,
    cardimage16,
    cardimage17,
    cardimage18,
    cardimage19,
    cardimage20,
    cardimage21,
    cardimage22,
    cardimage23,
    cardimage24,
    galleryimage1,
    galleryimage2,
    galleryimage3,
    galleryimage4,
    galleryimage5,
    galleryimage6,
    galleryimage7,
    galleryimage8,
    galleryimage9,
    galleryimage10,
    galleryimage12,
    galleryimage11,
    galleryimage13,
    galleryimage14,
    logo4,
    madabam02,
    profile,
    profile1,
    profile2,
    profile3,

    line15,
    line16,
    line17,
    line18,
    Vector,
    star,
    newimg,
    img2,
    imagewed1,
    imagewed2,
    imagewed3,
    hall1,
    banquet,
    rangeslider,
    newlogo,
    image3,
    star1_resize,
    mahall,
    mandapam_new,
    list_venue_pageimg,
    venue1_new,
    logo_event,
    logo,
    mandap_yel,
    foot_1,
    foot_2,
    foot_3,
    foot_4,
    foot_5,
    foot_6,
    image_6,
    image_8,
    image_9,
    image_10,
    image_11,
    image_12,
    arr,
    venu_carousels,
    venu_caro1,
    venu_caro2,
    venu_caro3,
    venu_caro4,
    venu_caro5,

    venu_caro6,
    venu_caro7,
    venu_caro8,

venu_caro9,
venu_caro10,
venu_caro11,
venu_caro12,
error_404,
Wedding_Resorts,
Banquet_Halls,
beach_house,
Convention_Halls,
Farmhouses,
Function_Halls,
Marriage_Halls,
Marriage_Garden,
Kalyana_Mandapams,
mini_hall,
Party_Halls,
Resort,
Wedding_Hotels,
Wedding_Lawns,
fav_icon

}
export default Images;