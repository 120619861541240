import React from 'react'
import "../Style.css"
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { secondData } from '../BannnerData.js/secondCard'

import { apiURL } from '../../../Layout/Common/confiqurl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Images from '../../../Images'
import { useNavigate } from 'react-router-dom'
import BannerNext from '../Main/BannerNext'
import Header from '../Main/Header'
const Part1 = (props) => {
  const { allData } = props
  const [click, setClick] = useState("")
  const [state, setState] = useState([])
  const [Details, setDetails] = useState(allData)
  const [isTruncated, setIsTruncated] = useState(true);
  const MAX_CHARACTERS = 200;


  const navigate = useNavigate()
  useEffect(() => {

    getData()
  }, []);
  const getData = () => {
    axios.get(`${apiURL}/get_PlaceType`, {
      headers: {

        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => {

        setState(response.data)
      })
  }


  const GetcardDetails = (e) => {
    setClick(e.target.value)
    const placetype = e.target.value


    const payload = {
      Place_type: placetype
    };

    axios.post(`${apiURL}/Homepage_details`, payload, {
      headers: {
        "accept": "application/json",
        "Content-Type": " application/json",

      }
    },
    )
      .then((response) => {

        setDetails(response.data)
      })

  }


  return (
    <div>
      <Header />
      <BannerNext setDetails={setDetails} />

      <div className='main px-3  mt-5 mx-5'>



        <div className='row'  >
          {
            state.map((data) => {
              return (
                <div className='col-lg-2 col-sm-4' >
                  <button type='button' class={click === data.data_value ? " px-5 mb-4 py-1 button-group w-100  clicked" : "px-3 w-100 button-group py-1 mb-4  "} value={data.data_value} onClick={(e) => GetcardDetails(e)}  > {data.data_value}</button>

                  <div>
                    </div>
                </div>
              )
            })
          }


        </div>

        <div className="Banner2 mx-5 px-3" >
           <div className=' row'>
            {
              Details.slice(0, 18).map((item) => {

                return (
                  <div className='col-lg-4 col-sm-6  my-2'>
                    <div className="card px-4 pt-4" style={{ height: "590px" }}>
                      
                      {item.images && (item.images.length >= 1 || item.images.length >= 0) && item.images.slice(1, 2).map((image) =>
                        (image.image && image.image != '') ? (
                          <img src={`data:image/jpeg;base64,${image.image}`} height={220} />


                        ) : ("")

                      )}
                      {item.images && (item.images.length == 0 || item.images.length == 1) &&
                        (
                          <img src={Images.imagek} height={220} alt='' />

                        )
                      }


                      <div className='' style={{ height: "60px" }}>

                        <h5 className='Carousel-name pt-3'>{item.mandapam_name}</h5>
                      </div>
                      <div className='mt-3' style={{ height: "80px" }}>
                        <h6 className='Carousel-name pt-1'><FontAwesomeIcon className='  mx-1' icon={faLocationDot} />{item.address}</h6>
                      </div>
                      <div style={{ minHeight: "150px" }}>
                        <p className="price" style={{ fontSize: "18px", minHeight: "350px" }}><span>{isTruncated && item.about ? item.about.slice(0, MAX_CHARACTERS) : item.about}</span>  .....</p>
                      </div>
                      <div>
                        <div className='col-12 mt-2'>
                          <button type="button" class="btn px-5 mt-4 text-white w-100" style={{ backgroundColor: " #836D5D" }} onClick={() => navigate("/FullDetails", { state: item })} >check Options</button>

                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>

        </div>


      </div>
    </div>
  )
}

export default Part1
