  import {React,useEffect} from 'react'
  import { Link } from 'react-router-dom'
  import "../Style.css"
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
  const Banner5 = () => {
   
    return (

      <div className='venue-image1 mt-5 mb-5'>
        <div class="talk padt-20 padb-20">
          <div class="container text-center">
            <div class="talk_to_agent" >
              <h5 class="bold-21">Need help to List your venue? 
                <Link to='/ListVenue' className='venuelink'onClick={() => window.scrollTo(0, 0)}>Click Here for Help.</Link>
                  We are just a click away!</h5>
            </div>
          </div>
        </div>
        
      </div>
    )
  }

  export default Banner5
