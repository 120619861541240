import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { apiURL } from '../../Layout/Common/confiqurl';
import Banner from './Main/Banner';
import Banner2 from './Main/Banner2';
import Header from './Main/Header';
import Banner3 from './Main/Banner3';
import Banner4 from './Main/Banner4';
import Banner5 from './Main/Banner5';
import Banner6 from './Main/Banner6';
import Footer from './Footer';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import BannerVenue from './Main/BannerVenue';

const LandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const [city, setCity] = useState('');
  const [cityId, setCityId] = useState();
  const [citynameheader, setCitynameheader] = useState('');
  const [arealist, setArealist] = useState([]);
  const [venues, setVenues] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [onclickvenue, setOnclickvenue] = useState(null);
  const [mandapamlist, setMandapamlist] = useState([]);

  useEffect(() => {
    const citynameheadernewapi = sessionStorage.getItem("citynameheadernewapi");
    if (citynameheadernewapi == null) {
      getLocation();
    } else {
      setCity(citynameheadernewapi);
      fetcharea(citynameheadernewapi);
    }
  }, []);

  useEffect(() => {
    fetchVenues();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getCityDetails(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const getCityDetails = (latitude, longitude) => {
    debugger;
    Axios.get(`${apiURL}/get_venues_from_location/${latitude}/${longitude}`, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        if (response.data.district) {
          const cityname = response.data.district;
          getLocationWithId(cityname);
          fetcharea(cityname);
          setCity(cityname);
          fetchcitymandapam(cityname);

          if (cityname.toLowerCase() !== 'chennai') {
            sessionStorage.setItem('citynamebindin_index_session', cityname);
            sessionStorage.setItem('cityid_label', cityname);
            sessionStorage.setItem('cityname', cityname);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching city details:", error);
        handleDefaultCity();
      });
  };

  const fetchcitymandapam = (city) => {
    axios.get(`${apiURL}/Homepage_location_data?city=${city}`)
      .then(response => {
        setMandapamlist(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const fetcharea = (cityname) => {
    if (!cityname) {
      cityname = 'Chennai';
    }
    axios.get(`${apiURL}/Homepage_location_data_by_city_and_location?city=${cityname}`, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        let options;
        if (cityname === 'Chennai') {
          options = response.data.data.slice(0, 5).map(item => ({
            value: item.id,
            label: item.location_name
          }));
        } else {
          options = response.data.data.map(item => ({
            value: item.id,
            label: item.location_name
          }));
        }
        setArealist(options);
        sessionStorage.setItem('arealist', JSON.stringify(options));
      })
      .catch((error) => {
        console.error("Error fetching area data:", error);
      });
  };

  const fetchVenues = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_venue_masterall`);
      setVenues(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getLocationWithId = (selectedCity) => {
    axios.get(`${apiURL}/get_location_name?location_name=${selectedCity}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const cityId = response.data[0].city_id;
          sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
          sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
        } else {
          console.warn("No data found for the selected city, setting default city.");
          toast.warn("Selected city not found. Defaulting to Chennai.");
        }
      })
      .catch((error) => {
        console.error("API call failed:", error);
        toast.error("Error fetching city data. Defaulting to Chennai.");
        handleDefaultCity();
      });
  };

  const handleDefaultCity = () => {
    axios.get(`${apiURL}/get_location_name?location_name=Chennai`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const cityId = response.data[0].city_id;
          sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
          sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
          sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
          const storedCityName = sessionStorage.getItem('citynameheadernewapi1');
          if (storedCityName && storedCityName.toLowerCase() !== 'chennai') {
            setTimeout(() => {
              toast.error('Unable to find the city, Default location set to Chennai');
            }, 1000);
          }
        } else {
          toast.error('Error fetching Chennai location data.');
        }
      })
      .catch((error) => {
        console.error('Error fetching Chennai location data:', error);
        toast.error('Error fetching location data. Please try again later.');
      });
  };

  const handleChange = (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);
    if (selectedCity.toLowerCase() !== 'chennai') {
      sessionStorage.setItem('citynamebindin_index_session', selectedCity);
    }
  };

  return (
    <div>
      <select
        className='form-select location-drop'
        type='select'
        value={city}
        onChange={handleChange}
      >
        <option value='Chennai'>Chennai</option>
        {citylist.map(city => (
          <option key={city.id} value={city.city_name}>{city.city_name}</option>
        ))}
      </select>
      <Header city={city} selectedLocality={selectedLocality} onclickvenue={onclickvenue} city1={cityId} />
      <Banner city={city} cityidnew={cityId} />
      <Banner4 />
      <Banner3 mandapamlist={mandapamlist} city={city} />
      <Banner2 />
      <Banner5 />
      <BannerVenue />
      <Banner6 city={city} arealist={arealist} />
      <Footer />
    </div>
  );
};

export default LandingPage;



// import React, { useState, useEffect } from 'react';
// import Axios from 'axios';
// import { apiURL } from '../../Layout/Common/confiqurl';
// import Banner from './Main/Banner'
// import Banner2 from './Main/Banner2'
// import Header from './Main/Header'
// import Banner3 from './Main/Banner3'
// import Banner4 from './Main/Banner4'
// import Banner5 from './Main/Banner5'
// import Banner6 from './Main/Banner6'
// import { Toaster, toast } from 'react-hot-toast' 
// import axios from 'axios';
// import { Carousel } from 'react-responsive-carousel';
// import FullDetails from './FullDetails';
// import Navbar from './AdvancedSearch/Navbar';
// import Contactus from './Contact/Contactus';
// import BannerVenue from './Main/BannerVenue';
// import Footer from './Footer';

// const LandingPage = () => {
//   const [loading, setLoading] = useState(true);
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);
//   const [error, setError] = useState(null);
//   const [city, setcity] = useState('')
//   const [cityName, setcityName] = useState('')
//   const [mandapamlist, setmadapamlist] = useState([]);
//   const [cityidnew, setcityidnew] = useState();
//   const [cityId, setcityId] = useState();
//   const [citynameheader, setcitynameheader] = useState('')
//   const [arealist, setarealist] = useState([]);
//   const [cityname, setcityname] = useState('')
//   const locality = "Selected Locality";
//   const [venues, setvenues] = useState([]);
//   const venue = "onclickvenue"
//   const [selectedLocality, setSelectedLocality] = useState(null);
//   const [city1, setcity1] = useState(null);
//   const [onclickvenue, setonclickvenue] = useState(null);
//   const [citylist, setcitylist] = useState([])
//   useEffect(() => {
//     const citySession = sessionStorage.getItem("citid.labelo");
//     const citynameheadernewapi = sessionStorage.getItem("citynameheadernewapi");
//     if (citynameheadernewapi == null) {
//       getLocation();
//       getCityDetails(latitude, longitude);
//     } else {
//     }
//   }, []);

//   useEffect(() => {
//     fetcharea(city);
//   }, []);

//   const getLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLatitude(position.coords.latitude);
//           setLongitude(position.coords.longitude);
//           getCityDetails(position.coords.latitude, position.coords.longitude)
//         },
//         (error) => {
//           setError(error.message);
//         }
//       );
//     } else {
//       setError("Geolocation is not supported by this browser.");
//     }
//   };
//   const getCityDetails = (latitude, longitude) => {
//     Axios.get(encodeURI(`${apiURL}/get_venues_from_location/${latitude}/${longitude}`), {
//       headers: {
//         "accept": "application/json",
//         "Content-Type": "application/json",
//       }
//     })
//       .then((response) => {
//         if (response.data.district) {
//           const cityname = response.data.district;
//           getlocationwithid(cityname);
//           fetcharea(cityname)
//           setcity(cityname);
//           fetchcitymandapam(cityname)
//           sessionStorage.setItem('citynamebindin index session', (cityname));
//           sessionStorage.setItem('cityid.label', (cityname))
//           sessionStorage.setItem('cityname', cityname);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching city details:", error);
//         handleDefaultCity();
//       });
//   };
//   const fetchcitymandapam = (city) => {
//     axios.get(`${apiURL}/Homepage_location_data?city=${city}`)
//       .then(response => {
//         setmadapamlist(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }
//   const fetcharea = (cityname) => {
//     if (!cityname) {
//       cityname = 'Chennai';
//     }
//     axios.get(`${apiURL}/Homepage_location_data_by_city_and_location?city=${cityname}`, {
//       headers: {

//         "accept": "application/json",
//         "Content-Type": " application/json",
//       }
//     },)
//       .then((response) => {
//         let options;
//         if (cityname === 'Chennai') {
//           options = response.data.data.slice(0, 5).map(item => ({
//             value: item.id,
//             label: item.location_name
//           }));
//         } else {
//           options = response.data.data.map(item => ({
//             value: item.id,
//             label: item.location_name
//           }));
//         }
//         setarealist(options);
//         sessionStorage.setItem('arealist', JSON.stringify(options));
//       })
//       .catch((error) => {
//         console.error("Error fetching area data:", error);
//       });
//   }
//   useEffect(() => {
//     fetchVenues();
//     const cityIds = citylist.map(city => city.id);
//     sessionStorage.setItem('selectedCityIds', JSON.stringify(cityIds));
//     sessionStorage.setItem("citynamestore", cityname)
//     const storedArealist = sessionStorage.getItem('arealist');
//   }, []);
//   const storedCity = sessionStorage.getItem('citynamebindin index session');
//   const getlocationwithid = (selectedCity) => {
//     axios.get(`${apiURL}/get_location_name?location_name=${selectedCity}`, {
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//     })
//       .then((response) => {
//         if (response.data && response.data.length > 0) {
//           const cityId = response.data[0].city_id;
//           sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
//           sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
//           sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
//           sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
//         }
//         else {
//           console.warn("No data found for the selected city, setting default city.");
//           toast.warn("Selected city not found. Defaulting to Chennai.");
//         }
//       })
//       .catch((error) => {
//         console.error("API call failed:", error);
//         toast.error("Error fetching city data. Defaulting to Chennai.");
//         handleDefaultCity();
//       });
//   };
//   const handleDefaultCity = () => {
//     axios.get(`${apiURL}/get_location_name?location_name=Chennai`, {
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//     })
//       .then((response) => {
//         if (response.data && response.data.length > 0) {
//           const cityId = response.data[0].city_id;
//           sessionStorage.setItem('citynameheadernewapicityid', response.data[0].city_id);
//           sessionStorage.setItem('citynameheadernewapi', response.data[0].location_name);
//           sessionStorage.setItem('citynameheadernewapicityid1', response.data[0].city_id);
//           sessionStorage.setItem('citynameheadernewapi1', response.data[0].location_name);
//           const storedCityName = sessionStorage.getItem('citynameheadernewapi1');
//           if (storedCityName && storedCityName.toLowerCase() === 'Chennai') {
//             setTimeout(() => {
//               toast.error('Unable to find the city, Default location set to Chennai');
//             }, 1000);
//           }

//         } else {
//           toast.error(`Error fetching Chennai location data.`);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching Chennai location data:', error);
//         toast.error('Error fetching location data. Please try again later.');
//       });
//   };
//   if (cityId == '') {
//     setTimeout(() => {
//       toast.error('Unable to find the city, Default location set to Chennai');
//     }, 1000);
//   }
//   const handleChange = (e) => {
//     const selectedCity = e.target.value;
//     setcityname(selectedCity);
//     sessionStorage.setItem('citynamebindin index session****', selectedCity);
//   };

//   const fetchVenues = async () => {
//     try {
//       const response = await axios.get(`${apiURL}/get_venue_masterall`);
//       setvenues(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };
//   const handleLocalityClick = (locality, venue) => {
//     setSelectedLocality(locality);
//     setonclickvenue(venue);
//   };
//   return (
//     <div>
//       <select
//         className='form-select location-drop'
//         type='select'
//         value={cityname}
//         onChange={handleChange}
//       >
//         <option value='Chennai'>Chennai</option>
//         {citylist.map(city => (
//           <option key={city.id} value={city.city_name}>{city.city_name}</option>
//         ))}
//       </select>
//       <Header city={city} selectedLocality={locality} onclickvenue={venue} city1={cityId} />
//       <Banner city={city} cityidnew={cityidnew} />
//       <Banner4 />
//       <Banner3 mandapamlist={mandapamlist} city={city} />
//       <Banner2 />
//       <Banner5 />
//       <BannerVenue />
//       <Banner6 city={city} arealist={arealist} />
//       <Footer /> 
//     </div> 
//   )
// } 
// export default LandingPage