import React, { useState, useEffect } from 'react';
import Header from '../Main/Header'
import ImageGallery from './ImageGallery'
import About from './About'
import Details from './Details'
import Reviews from './Reviews'
import Banner6 from '../Main/Banner6'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { apiURL } from '../../../Layout/Common/confiqurl';
// import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Banner3 from '../Main/Banner3';


const FullDetails = () => {
  const location = useLocation()
  const allDetails = location.state
  const id = location.state ? location.state.id : null;
  const startDate = location.state ? location.state.startDate : null;
  const endDate = location.state ? location.state.endDate : null;
  console.log(startDate,endDate,"endDate")
  const [individualMandapam, setIndividualMandapam] = useState(null); 
  const [error, setError] = useState(null);
  const [city, setcity] = useState('');


  const [arealist, setarealist] = useState([]);


  useEffect(() => {

    window.scrollTo(0, 0);
    if (id) {
      getindividualmadapam(id);
    }
    const storedCity = sessionStorage.getItem('citynamebindin index session');

    if (storedCity !== null) {
      // Remove quotes from city name if they exist
      const cityWithoutQuotes = storedCity.replace(/^"(.*)"$/, '$1');


      // Set the city state with the modified city name
      setcity(cityWithoutQuotes);
    } else {

      // Handle the case where city is not found in sessionStorage
    } const storedArealist = sessionStorage.getItem('arealist');

    if (storedArealist) {
      setarealist(JSON.parse(storedArealist));
    }





  }, [id]);


  const getindividualmadapam = (id) => {
    axios.get(`${apiURL}/mandapam_details_by_id?Mandapam_id=${id}`, {
      headers: {

        "accept": "application/json",
        "Content-Type": " application/json",
      }
    },)
      .then((response) => { 
        setIndividualMandapam(response.data);


      })
      .catch((error) => {
        console.error('Error fetching individual mandapam:', error);
        setError(error);
      });
  };



  return (
    <div>
      <Header city={city } />
      <ImageGallery allData={individualMandapam} />
      <About allData={individualMandapam} startDate={startDate} endDate={endDate}/>
      <Details allData={individualMandapam} />
      <Reviews />
        
      <Banner3 city={city}  />

      <Banner6 city={city} arealist={arealist} />
    </div>
  )
}

export default FullDetails
