import React from 'react'

import { useNavigate } from 'react-router-dom';
import './Errorpage.css'; 
import error_404 from '../../../Images/error_404.png';

const Errorpage = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/');
      };
  return (

<div className="container">
<div className="row align-items-center">
    <div className="col-lg-6 my-auto">
        <h1 style={{
            background: 'linear-gradient(310deg, #d60808, #ff6690)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize:'56px'
        }}>500 - Page Not Found!</h1>
        <h2 className='css'>Erm. Page not found</h2>
        <p style={{ fontSize: '1.25rem' }}>
            We suggest you go to the homepage while we solve this issue.
        </p>
        <button onClick={handleBackToHome} className="home-button">Back to Home</button>
    </div>
    <div className="col-lg-6">
        <img className="product-image mx-2" src={error_404} height={500} alt="venue" />
    </div>
</div>
</div>
);
};

export default Errorpage 
