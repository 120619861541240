import React from 'react'
import "../Style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Images from '../../../Images'
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons'
const Photo = () => {
  return (
    <div className='px-3   mt-5 mx-5  mb-5'>
      <div className='d-flex justify-content-end mt-1 mx-3'>
        <h6 className='text-black pt-1'><FontAwesomeIcon className='  mx-1' icon={faArrowUpFromBracket} />Share</h6>

      </div>
      <div className='row'>
        <div className='col-12 galleryimage-1'>
          <img src={Images.galleryimage11} alt='' className='w-100 mb-5' />


        </div>
        <div className='col-6 galleryimage-1'>
          <img src={Images.galleryimage6} alt='' className='w-100 mb-5' />


        </div>
        <div className='col-6 galleryimage-1'>
          <img src={Images.galleryimage7} alt='' className='w-100 mb-5' />


        </div>  <div className='col-6 galleryimage-1'>
          <img src={Images.galleryimage13} alt='' className='w-100 mb-5' />


        </div>  <div className='col-6 galleryimage-1'>
          <img src={Images.galleryimage9} alt='' className='w-100 mb-5' />


        </div>
        <div className='col-12 galleryimage-1'>
          <img src={Images.galleryimage12} alt='' className='w-100 mb-5' />


        </div>
        <div className='col-6 galleryimage-1'>
          <img src={Images.galleryimage10} alt='' className='w-100 mb-5' />


        </div>
        <div className='col-6 galleryimage-1'>
          <img src={Images.galleryimage8} alt='' className='w-100 mb-5' />


        </div>

      </div>

    </div>
  )
}

export default Photo
